import React, { useEffect, useState } from 'react';
import { ResizableBox } from '@dantecoder/react-resizablebox';
import { OnResizeHandler } from '@dantecoder/react-resizablebox/dist/cjs/types';
import { random } from '../../utils/helpers';
import { SignType } from './types';
import { DeleteOutlined } from '@ant-design/icons';
import { MIN_STAMP_SIZE } from '../../utils/consts';

interface Props {
  type: 'signature' | 'stamp';
  aspectRatio?: boolean | number;
  containerWidth?: number;
  containerHeight?: number;
  data: any;
  setShowObj: (value: any) => void;
}

const DD = ({
  aspectRatio = false,
  type,
  containerWidth,
  containerHeight,
  data,
  setShowObj,
}: Props) => {
  const [width, setWidth] = useState(
    data.width || MIN_STAMP_SIZE * (typeof aspectRatio === 'number' ? aspectRatio : 1),
  );
  const [height, setHeight] = useState(data.height || MIN_STAMP_SIZE);
  const [left, setLeft] = useState(data.left || random(0, 100));
  const [top, setTop] = useState(data.top || random(0, 100));
  const [showPopup, setShowPopup] = useState(false);

  const onDragHandler = ({ style }: any) => {
    if (style.left >= 0 && containerWidth && style.left + width < containerWidth) {
      setLeft(style.left);
    }
    if (style.top >= 0 && containerHeight && style.top + height < containerHeight) {
      setTop(style.top);
    }
  };

  const onResizeHandler: OnResizeHandler = ({ style }) => {
    onDragHandler({ style });
    setWidth(style.width);
    setHeight(style.height);
  };

  const handleDelete = () => {
    setShowObj((prev: any) => {
      const newObj = { ...prev };
      newObj[type] = newObj[type].filter((item: SignType) => item.id !== data.id);
      return newObj;
    });
  };

  useEffect(() => {
    setShowObj((prev: any) => {
      const newObj = { ...prev };
      const objIdx = newObj[type].findIndex((item: SignType) => item.id === data.id);
      if (objIdx >= 0) {
        newObj[type][objIdx].left = left;
        newObj[type][objIdx].top = top;
        newObj[type][objIdx].x0 = left + width;
        newObj[type][objIdx].x = containerWidth ? containerWidth - (left + width) : 0;
        newObj[type][objIdx].xPercent = containerWidth
          ? (newObj[type][objIdx].x / containerWidth) * 100
          : 0;
        newObj[type][objIdx].y0 = top + height;
        newObj[type][objIdx].y = containerHeight ? containerHeight - (top + height) : 0;
        newObj[type][objIdx].yPercent = containerHeight
          ? (newObj[type][objIdx].y / containerHeight) * 100
          : 0;
        newObj[type][objIdx].width = width;
        newObj[type][objIdx].widthPercent = containerWidth ? (width / containerWidth) * 100 : 0;
        newObj[type][objIdx].height = height;
        newObj[type][objIdx].heightPercent = containerHeight ? (height / containerHeight) * 100 : 0;
      }
      return newObj;
    });
  }, [left, top, width, height]);

  return (
    <div
      className="relative"
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
    >
      <ResizableBox
        className="text-[#1677FF] font-semibold flex items-center justify-center text-center z-[999] bg-no-repeat bg-contain bg-center"
        style={{
          border: '1px dashed #1677FF',
          backgroundImage: `url(/images/${type}.webp)`,
          backgroundColor: 'rgba(186, 224, 255, 0.60)',
        }}
        color="#1677FF"
        aspectRatio={aspectRatio}
        rotatable={false}
        width={width}
        height={height}
        minWidth={MIN_STAMP_SIZE * (typeof aspectRatio === 'number' ? aspectRatio : 1)}
        minHeight={MIN_STAMP_SIZE}
        left={left}
        top={top}
        onDrag={onDragHandler}
        onResize={onResizeHandler}
        relativeHandlers={false}
      />
      <div
        className="absolute flex gap-3 py-[6px] px-3 bg-white z-[1000] rounded-[36px]         "
        style={{
          opacity: showPopup ? 1 : 0,
          top: top - 30,
          left: left + width / 2 - 20,
          boxShadow:
            '0px 1px 2px -2px rgba(0, 0, 0, 0.16), 0px 3px 6px 0px rgba(0, 0, 0, 0.02), 0px 5px 13px 3px rgba(0, 0, 0, 0.09)',
        }}
      >
        <DeleteOutlined style={{ color: '#FF4D4F' }} role="button" onClick={handleDelete} />
      </div>
    </div>
  );
};

export default DD;
