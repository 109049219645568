import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Page404() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full min-h-[60vh] justify-center items-center">
      <h1>Страница не найдена</h1>
      <Button type="primary" className="mt-8" onClick={() => navigate(-1)}>
        Назад
      </Button>
    </div>
  );
}
