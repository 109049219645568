export const APP_VERSION = 0.28;

export const RQ_OPT = { staleTime: 300_000 };

export const emailRegex =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]{1,}(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9-]{2,}\.)+[a-zA-Z]{2,}$/;

export const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{6,30}$/;

export const passwordRequirements =
  'Пароль должен содержать от 6 до 30 символов, минимум 1 букву, 1 цифру и 1 специальный символ, пробелы не допускаются.';

let isProd = process.env.NODE_ENV === 'production';
if (window.location.hostname.includes('dev')) {
  isProd = false;
}

export const BASE_URL = isProd ? 'https://signatorypro.com' : 'https://dev.signatorypro.com';
export const URL_API = `${BASE_URL}/crm/v1`;
export const MD_URL_API = `${BASE_URL}/api/v1`;

export const optionsItemsPerPage = [10, 25, 50, 100, 200];

export const DEBOUNCE_TIME_MS = 900;

export const ROLES = [
  { title: 'Суперадминистратор', name: 'ROLE_ADMIN' },
  { title: 'Управляющий менеджер', name: 'ROLE_SUPERVISOR' },
  { title: 'Менеджер', name: 'ROLE_MANAGER' },
];

export const DAYS = [
  { en: 'mon', ru: 'Пн' },
  { en: 'tue', ru: 'Вт' },
  { en: 'wed', ru: 'Ср' },
  { en: 'thu', ru: 'Чт' },
  { en: 'fri', ru: 'Пт' },
  { en: 'sat', ru: 'Сб' },
  { en: 'sun', ru: 'Вс' },
];

export const COMPANY_TYPES = ['physical', 'individual', 'legal_entity'];
export const COMPANY_TYPES_NAMES = {
  [COMPANY_TYPES[0]]: 'физ. лицо',
  [COMPANY_TYPES[1]]: 'ИП',
  [COMPANY_TYPES[2]]: 'юр. лицо',
};

export const languages = [
  { key: 'ru', label: 'РУССКИЙ' },
  { key: 'en', label: 'ENGLISH' },
];

export const selectLangText: any = {
  en: 'Select language',
  ru: 'Выберите язык',
};

export const MAX_PDF_SIZE_MB = 10;

export const DOC_TYPES = [
  { type: 'contract', name: 'Договор' },
  { type: 'additional_agreement', name: 'Дополнительное соглашение к договору' },
  { type: 'annex_to_contract', name: 'Приложение к договору' },
  { type: 'power_of_attorney', name: 'Доверенность' },
  { type: 'procedural_document', name: 'Процессуальный документ' },
  { type: 'claim', name: 'Претензия' },
  { type: 'letter', name: 'Письмо' },
];

export const A4_WIDTH_MM = 210;
export const A4_HEIGHT_MM = 297;
export const MIN_STAMP_SIZE = 50;
