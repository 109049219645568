import { Button, Divider, Dropdown, Form, Input, Modal, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { ImagesNames } from './imagesNames';
import { BASE_URL } from '../../utils/consts';
import { FullscreenOutlined } from '@ant-design/icons';
import { assignManagerToClient, requestClientStamp } from '../../utils/httpServices/global';
import { toast } from 'react-toastify';
import ModalStatus from './ModalStatus';
import { validateEmail } from '../../utils/helpers';

const { Step } = Steps;

export default function ModalVerification({
  open,
  data,
  dataClient,
  onEdit,
  onVerify,
  onClose,
  setShowImage,
  dataManagers,
  dataCompanies,
  dataAccounts,
  refetch,
  setIsShowCreateOrgModal,
  setIsShowCreateAccModal,
  company,
  setCompany,
  step = 0,
  setStep,
}: any) {
  const { refetchClient, refetchClients, refetchAccounts, refetchCompanies } = refetch;

  const [form] = Form.useForm();
  const [selectedImgIdx, setSelectedImgIdx] = useState(0);
  const [manager, setManager] = useState<any>(null);
  const [stampRequested, setStampRequested] = useState(false);

  const handleSelectManager = (val: any) => {
    const mng = (dataManagers?.items || []).find((el: any) => +el.id === +val.key);
    setManager(mng || null);
    if (mng) {
      assignManagerToClient(data?.id, { managerId: mng?.id || 0 }).then((res) => {
        if (res?.manager?.id && res?.manager?.name) {
          toast(`Менеджер ${res.manager.name} назначен`, { type: 'success' });
        }
        return res;
      });
    }
  };

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        if (onEdit) {
          onEdit(data.id, values).then((res: any) => {
            if (res && !res.error) {
              setStep((prev: number) => prev + 1);
              if (values) form.setFieldsValue({ ...values });
            }
          });
        }
      })
      .catch((error) => console.log('error:', error));
  };

  const onFinish3 = () => {
    onVerify(data.id).then((res: any) => {
      if (res && !res.error) {
        onClose();
        return res;
      }
    });
  };

  useEffect(() => {
    const mng = (dataManagers?.items || []).find(
      (el: any) => el.name === dataClient?.manager?.name,
    );
    setManager(mng || null);
  }, []);

  useEffect(() => {
    if (data && step === 0) {
      form.setFieldsValue({ ...data });
      const { name, phone, email, passport, position } = data;
      sessionStorage.setItem('values', JSON.stringify({ name, phone, email, passport, position }));
    }
  }, [data, step]);

  const requestStamp = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    requestClientStamp(data.id).then((response) => {
      setStampRequested(true);
    });
  };

  return (
    <Modal
      centered
      maskClosable={false}
      getContainer={false}
      onCancel={onClose}
      footer={null}
      open={open}
      className="min-w-[50vw]"
    >
      <h2 className="mb-5">Верификация клиента</h2>
      <Steps current={step}>
        <Step title="Проверка" />
        <Step title="Данные организации" />
        <Step title="Менеджер" />
      </Steps>
      {step === 0 && (
        <Form
          layout="vertical"
          form={form}
          className="flex flex-col"
          name="form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onValuesChange={(changedValues, values) =>
            sessionStorage.setItem('values', JSON.stringify(values))
          }
        >
          <div className="flex gap-4 mt-4">
            <div className="flex flex-col w-[40%]">
              <Form.Item
                label="ФИО"
                name="name"
                rules={[
                  { required: true, message: 'Пожалуйста, введите Ф.И.О.' },
                  {
                    min: 1,
                    max: 32,
                    message: 'ФИО должна содержать от 1 до 32 символов',
                  },
                  {
                    pattern: /^[a-zA-Zа-яА-ЯЁё\s-]*$/,
                    message:
                      'ФИО должна содержать только русские или латинские буквы, символ "-" и пробел',
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="Паспорт"
                name="passport"
                rules={[
                  { required: true, message: 'Пожалуйста, введите паспортные данные' },
                  {
                    max: 20,
                    message: 'Максимальное количество знаков - 20',
                  },
                  {
                    pattern: /^[a-zA-Z0-9]*$/,
                    message: 'Поле должно содержать только цифры и буквы',
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="Телефон"
                name="phone"
                rules={[
                  { required: true, message: 'Пожалуйста, введите номер телефон' },
                  {
                    max: 12,
                    message: 'Максимальное количество знаков - 12',
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { validator: validateEmail },
                  {
                    max: 50,
                    message: 'Максимальное количество знаков - 50',
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>

              <Form.Item
                label="Должность"
                name="position"
                rules={[{ max: 32, message: 'Максимальное количество знаков - 32' }]}
              >
                <Input allowClear />
              </Form.Item>
            </div>
            <div className="flex flex-col w-[60%] gap-4">
              <div
                className="h-2/3 w-full bg-[#F1F1F1] rounded-[9px] bg-center bg-contain bg-no-repeat relative"
                style={{ backgroundImage: `url(${BASE_URL}${data[ImagesNames[selectedImgIdx]]})` }}
              >
                <Button
                  htmlType="button"
                  className="bg-white h-8 w-8 flex items-center justify-center absolute top-2 left-2"
                  onClick={() => setShowImage(BASE_URL + data[ImagesNames[selectedImgIdx]])}
                >
                  <FullscreenOutlined />
                </Button>
              </div>
              <div className="w-full h-[72px] flex gap-2 overflow-auto max-w-[100%]">
                {ImagesNames.map((fieldName, i) =>
                  data?.[fieldName] ? (
                    <button
                      type="button"
                      className="w-[72px] h-[72px] rounded-[6px] bg-no-repeat bg-contain bg-center"
                      style={{
                        backgroundImage: `url(${BASE_URL}${data[fieldName]})`,
                        border: `3px solid ${i === selectedImgIdx ? '#1677FF' : 'transparent'}`,
                      }}
                      key={fieldName}
                      onClick={() => setSelectedImgIdx(i)}
                    />
                  ) : null,
                )}
              </div>
              {data?.needStamp && (
                <button disabled={stampRequested} onClick={requestStamp} className="text-[#1677FF]">
                  {!stampRequested ? 'Запросить печать' : 'Печать запрошена'}
                </button>
              )}
            </div>
          </div>
          <Form.Item className="ml-auto">
            <Button onClick={onClose} className="mr-4">
              Отмена
            </Button>
            <Button type="primary" htmlType="submit">
              Далее
            </Button>
          </Form.Item>
        </Form>
      )}
      {step === 1 && (
        <div className="flex flex-col w-full mt-4">
          <ModalStatus
            isSmall
            open={step === 1}
            data={data}
            dataCompanies={dataCompanies}
            dataAccounts={dataAccounts}
            company={company}
            setCompany={setCompany}
            setIsShowCreateOrgModal={setIsShowCreateOrgModal}
            setIsShowCreateAccModal={setIsShowCreateAccModal}
            setStep={setStep}
            refetch={{ refetchClient, refetchClients, refetchAccounts, refetchCompanies }}
            onClose={() => setIsShowCreateAccModal(false)}
          />
        </div>
      )}
      {step === 2 && (
        <div className="w-full min-h-[30vh] flex flex-col justify-between">
          <div className="w-full">
            <h3 className="my-5">Выберите менеджера клиента</h3>
            <div className="w-1/2 mb-6">
              <Dropdown
                menu={{
                  items: (dataManagers?.items || []).map((el: any) => ({
                    key: el.id,
                    label: el.name,
                  })),
                  onClick: handleSelectManager,
                }}
                autoFocus
                trigger={['click']}
              >
                <a
                  tabIndex={0}
                  onClick={(e) => e.preventDefault()}
                  className="rounded-[6px] border px-4 py-1"
                  style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}
                >
                  <div className="inline-flex items-center justify-between w-full">
                    {manager?.name || 'Список менеджеров'}
                    <DownOutlined />
                  </div>
                </a>
              </Dropdown>
            </div>
          </div>
          <div className="flex flex-col items-end mt-auto w-full">
            <Divider />
            <div className="flex gap-4">
              <Button onClick={() => setStep(1)}>Назад</Button>
              <Button type="primary" onClick={onFinish3} disabled={!manager}>
                Верифицировать
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}
