import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Checkbox, Form, Input, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LoginIcon } from '../../components/Icons/Login';
import { authLogin, refreshToken } from '../../utils/httpServices/global';
import { validateEmail } from '../../utils/helpers';
import { DOCS_PATH, RECOVER_PASSWORD_PATH } from '../../utils/routes_consts';

const initialValues = {
  email: '',
  password: '',
};

const Login = observer(() => {
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<any>({});
  const [validation, setValidation] = useState<any>({});
  const navigate = useNavigate();

  const onFinish = useCallback(
    (values: any) => {
      form
        .validateFields()
        .then(async () => {
          const res = await authLogin(values);

          if (res?.error) {
            const msg = res.error?.response?.data?.message || res.error.message;
            // toast(msg, { type: 'error' });
            setErrors({ email: ' ', password: msg });
            setValidation({ validateStatus: 'error' });
          } else if (res?.token && res?.refreshToken) {
            if (values.remember) {
              localStorage.setItem('token', res.token);
              localStorage.setItem('refreshToken', res.refreshToken);
            }
            sessionStorage.setItem('token', res.token);
            sessionStorage.setItem('refreshToken', res.refreshToken);
            navigate(DOCS_PATH);
          }
        })
        .catch((errorInfo) => {
          const newErrors: any = {};
          errorInfo.errorFields.forEach((field: any) => {
            newErrors[field.name[0]] = field.errors[0];
          });
          setErrors(newErrors);
        });
    },
    [form, navigate],
  );

  const handleValuesChange = (changedValues: any, allValues: any) => {
    setErrors({});
    setValidation({});
  };

  useEffect(() => {
    const tokenLS = localStorage.getItem('token');
    const refreshTokenLS = localStorage.getItem('refreshToken');
    if (tokenLS && refreshTokenLS) {
      refreshToken(refreshTokenLS)
        .then((res) => {
          if (res?.token && res?.refreshToken) {
            localStorage.setItem('token', res.token);
            localStorage.setItem('refreshToken', res.refreshToken);
            navigate(DOCS_PATH);
          }
        })
        .catch((error) => console.error(error));
    }
  }, []);

  return (
    <div className="my-8 mx-[50px] flex flex-col items-center">
      <div className="text-center mb-4">
        <LoginIcon className="mx-auto" />
        <h1 className="mt-6 mb-1 text-[24px]">Авторизация</h1>
        <p className="text-sm opacity-45">Введите email и пароль</p>
      </div>
      <Form
        form={form}
        className="flex flex-col md:w-1/4 sm:w-1/3 w-full"
        name="login-form"
        initialValues={initialValues}
        onFinish={(vals) => onFinish(vals)}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Пожалуйста, введите email' },
            { validator: validateEmail },
            {
              max: 50,
              message: 'Максимальное количество знаков - 50',
            },
          ]}
          help={errors?.email}
          {...validation}
        >
          <Input placeholder="Email" allowClear autoFocus />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]}
          help={errors.password}
          {...validation}
        >
          <Input.Password placeholder="Пароль" allowClear />
        </Form.Item>

        <div className="flex items-center justify-between my-6">
          <Form.Item name="remember" valuePropName="checked" initialValue={false} noStyle>
            <Checkbox>Запомнить</Checkbox>
          </Form.Item>

          <Typography.Link href={RECOVER_PASSWORD_PATH}>Забыли пароль?</Typography.Link>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="w-full">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});

export default Login;
