import React from 'react';

import Login from './modules/Login';
import ForgotPass from './modules/ForgotPass';
import NewPass from './modules/NewPass';
import Employees from './modules/Employees';
import Clients from './modules/Clients';
import Roles from './modules/Employees/Roles';
import Docs from './modules/Docs';
import Settings from './modules/Settings';
import Doc from './modules/Doc';
import Page404 from './modules/Page404';
import Notifications from './modules/Notifications';
import NotifyPanel from './modules/NotifyPanel';
import Upload from './modules/Upload';
import DB from './modules/DB';
import AgreementPreview from './modules/AgreementPreview';
import PolicyPreview from './modules/Policy';
import ConditionsPreview from './modules/Conditions';
import {
  LOGIN_PATH,
  RECOVER_PASSWORD_PATH,
  NEW_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  EMPLOYEES_PATH,
  ROLES_PATH,
  CLIENTS_PATH,
  SETTINGS_PATH,
  LICENSE_PATH,
  POLICY_PATH,
  AGREEMENT_PATH,
  DOC_INFO_PATH,
  DOCS_PATH,
  NOTIFICATIONS_PATH,
  NOTIFY_PATH,
  UPLOAD_INFO_PATH,
  UPLOADS_PATH,
  DB_PATH,
  ALL_PATH,
} from './utils/routes_consts';

const routes = [
  { path: LOGIN_PATH, element: <Login />, withNoToken: true },
  { path: RECOVER_PASSWORD_PATH, element: <ForgotPass />, withNoToken: true },
  { path: NEW_PASSWORD_PATH, element: <NewPass />, withNoToken: true },
  { path: RESET_PASSWORD_PATH, element: <NewPass />, withNoToken: true },
  { path: EMPLOYEES_PATH, element: <Employees /> },
  { path: ROLES_PATH, element: <Roles /> },
  { path: CLIENTS_PATH, element: <Clients /> },
  { path: SETTINGS_PATH, element: <Settings /> },
  { path: LICENSE_PATH, element: <AgreementPreview /> },
  { path: POLICY_PATH, element: <PolicyPreview /> },
  { path: AGREEMENT_PATH, element: <ConditionsPreview /> },
  { path: DOC_INFO_PATH, element: <Doc /> },
  { path: DOCS_PATH, element: <Docs /> },
  { path: NOTIFICATIONS_PATH, element: <Notifications /> },
  { path: NOTIFY_PATH, element: <NotifyPanel /> },
  { path: UPLOAD_INFO_PATH, element: <Upload /> },
  { path: UPLOADS_PATH, element: <Upload /> },
  { path: DB_PATH, element: <DB /> },
  { path: ALL_PATH, element: <Page404 /> },
];

export default routes;
