import React from 'react';
import { SVGProps } from './types';

export const LockFilledIcon = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    {...props}
  >
    <path
      d="M58.5 32.625H53.7188V16.875C53.7188 11.9039 49.6898 7.875 44.7188 7.875H27.2812C22.3102 7.875 18.2812 11.9039 18.2812 16.875V32.625H13.5C12.2555 32.625 11.25 33.6305 11.25 34.875V61.875C11.25 63.1195 12.2555 64.125 13.5 64.125H58.5C59.7445 64.125 60.75 63.1195 60.75 61.875V34.875C60.75 33.6305 59.7445 32.625 58.5 32.625ZM37.9688 49.2891V53.0156C37.9688 53.325 37.7156 53.5781 37.4062 53.5781H34.5938C34.2844 53.5781 34.0312 53.325 34.0312 53.0156V49.2891C33.4508 48.8724 33.0176 48.2822 32.794 47.6036C32.5703 46.925 32.5677 46.193 32.7866 45.5128C33.0055 44.8327 33.4345 44.2395 34.012 43.8187C34.5894 43.3979 35.2855 43.1712 36 43.1712C36.7145 43.1712 37.4106 43.3979 37.988 43.8187C38.5655 44.2395 38.9945 44.8327 39.2134 45.5128C39.4323 46.193 39.4297 46.925 39.206 47.6036C38.9824 48.2822 38.5492 48.8724 37.9688 49.2891V49.2891ZM48.6562 32.625H23.3438V16.875C23.3438 14.7023 25.1086 12.9375 27.2812 12.9375H44.7188C46.8914 12.9375 48.6562 14.7023 48.6562 16.875V32.625Z"
      fill="#52C41A"
    />
  </svg>
);
