import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, Input } from 'antd';
import { toast } from 'react-toastify';
import { SafetyCertificateFilledIcon } from '../../components/Icons/SafetyCertificateFilled';
import { CheckCircleOutlinedIcon } from '../../components/Icons/CheckCircleOutlined';
import clsx from 'clsx';
import { authResetPassword } from '../../utils/httpServices/global';
import { validateEmail } from '../../utils/helpers';

const ForgotPass = observer(() => {
  const [email, setEmail] = useState('');
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<any>({});
  const [validation, setValidation] = useState<any>({});
  const [step, setStep] = useState(1);
  const [seconds, setSeconds] = useState(60);

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then(async () => {
        const res = await authResetPassword(values);

        if (res?.error) {
          const msg = res.error?.response?.data?.message || res.error.message;
          toast(msg, { type: 'error' });
          setErrors({ email: msg });
          setValidation({ validateStatus: 'error' });
        } else {
          setErrors({});
          setValidation({});
          if (step !== 2) setStep(2);
        }
      })
      .catch((errorInfo) => {
        const newErrors: any = {};
        errorInfo.errorFields.forEach((field: any) => {
          newErrors[field.name[0]] = field.errors[0];
        });
        setErrors(newErrors);
      });
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    setEmail(changedValues?.email || '');
    setErrors({});
    setValidation({});
  };

  useEffect(() => {
    if (step === 1 && seconds !== 60) {
      setSeconds(60);
    }
    if (step === 2) {
      setSeconds(61);
    }
  }, [step]);

  useEffect(() => {
    if (step !== 2) return;
    if (seconds > 0) {
      setTimeout(() => {
        setSeconds((prev) => (prev > 0 ? prev - 1 : prev));
      }, 1000);
    }
  }, [seconds]);

  return (
    <div className="my-8 mx-[50px] flex flex-col items-center">
      <div className="text-center mb-4">
        {step === 1 ? (
          <SafetyCertificateFilledIcon className="mx-auto" />
        ) : (
          <CheckCircleOutlinedIcon className="mx-auto" />
        )}
        <h1 className="mt-6 mb-1 text-[24px]">
          {step === 1 ? 'Восстановление пароля' : 'Отправили ссылку'}
        </h1>
        <p
          className={clsx('text-sm opacity-45 mx-auto', step === 1 ? 'max-w-[75%]' : 'max-w-[50%]')}
        >
          {step === 1
            ? 'Введите email. На него отправим ссылку для восстановления пароля'
            : `На ${email} отправили ссылку для восстановления пароля. Если письмо не пришло, проверьте папку «Спам»`}
        </p>
      </div>
      {step === 1 && (
        <Form
          form={form}
          className="flex flex-col md:w-1/4 sm:w-1/3 w-full"
          name="forgot-pass-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onValuesChange={handleValuesChange}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Пожалуйста, введите email' },
              { validator: validateEmail },
              {
                max: 50,
                message: 'Максимальное количество знаков - 50',
              },
            ]}
            help={errors?.email}
            {...validation}
          >
            <Input placeholder="Email" allowClear />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="w-full mt-6" disabled={!email}>
              Восстановить
            </Button>
          </Form.Item>
        </Form>
      )}
      {step === 2 && (
        <Button
          className="md:w-[400px] mt-4 bg-white"
          disabled={!email || !!seconds}
          onClick={() => onFinish({ email })}
        >
          Отправить ещё раз {seconds > 0 ? `(${seconds.toString().padStart(2, '0')} сек)` : ''}
        </Button>
      )}
    </div>
  );
});

export default ForgotPass;
