import React, { useEffect, useMemo, useState } from 'react';
import { Menu, Dropdown, Modal, Button } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { menuItems } from './menuItems';
import { toast } from 'react-toastify';
import { BellFilled, DownOutlined, SettingFilled, UserOutlined } from '@ant-design/icons';
import { languages, selectLangText } from '../../utils/consts';
import clsx from 'clsx';
import globalController from '../../mobx/GlobalController';
import { Divider } from '../Divider';
import { observer } from 'mobx-react-lite';
import { LOGIN_PATH, NOTIFY_PATH, SETTINGS_PATH } from '../../utils/routes_consts';

const browserLanguage = window.navigator.language.slice(0, 2);

const Header = observer(() => {
  const location = useLocation();
  const { pathname, search } = location;
  const isPrint = search?.includes('print');
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [selectedKeysMenu, setSelectedKeysMenu] = useState<any[]>([]);
  const isShowMenu = !!menuItems.find((el) => el.link.includes(pathname));
  const [isShowLogoutModal, setIsShowLogoutModal] = useState(false);
  const user = globalController.getUser();
  const unreadNotifications = user?.newNotificationCount || 0;
  const isLoginPage = pathname === LOGIN_PATH;

  const userMenu = [
    {
      key: 'logout',
      label: (
        <div className="bg-[#001529] text-white flex flex-col text-sm">
          {!!user && (
            <>
              <p className="font-semibold">{user?.name}</p>
              <p className="opacity-65">{user?.roleName}</p>
              <Divider className="opacity-25 my-2" />
            </>
          )}
          <div className="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path
                d="M13.5619 11.4365H12.4634C12.3884 11.4365 12.3181 11.4694 12.2712 11.5272C12.1619 11.66 12.0447 11.7881 11.9212 11.91C11.4164 12.4153 10.8184 12.8181 10.1603 13.0959C9.47852 13.3839 8.74573 13.5316 8.00562 13.5303C7.25718 13.5303 6.53218 13.3834 5.85093 13.0959C5.19285 12.8181 4.59485 12.4153 4.09 11.91C3.58424 11.4063 3.18096 10.8094 2.9025 10.1522C2.61343 9.47092 2.46812 8.74748 2.46812 7.99905C2.46812 7.25061 2.615 6.52717 2.9025 5.84592C3.18062 5.18811 3.58062 4.59592 4.09 4.08811C4.59937 3.5803 5.19156 3.1803 5.85093 2.90217C6.53218 2.61467 7.25718 2.4678 8.00562 2.4678C8.75406 2.4678 9.47906 2.61311 10.1603 2.90217C10.8197 3.1803 11.4119 3.5803 11.9212 4.08811C12.0447 4.21155 12.1603 4.33967 12.2712 4.47092C12.3181 4.52873 12.39 4.56155 12.4634 4.56155H13.5619C13.6603 4.56155 13.7212 4.45217 13.6666 4.36936C12.4681 2.50686 10.3712 1.27405 7.98843 1.2803C4.24468 1.28967 1.24312 4.32874 1.28062 8.0678C1.31812 11.7475 4.315 14.7178 8.00562 14.7178C10.3822 14.7178 12.4697 13.4865 13.6666 11.6287C13.7197 11.5459 13.6603 11.4365 13.5619 11.4365ZM14.9509 7.90061L12.7337 6.15061C12.6509 6.08498 12.5306 6.14436 12.5306 6.24905V7.43655H7.62437C7.55562 7.43655 7.49937 7.4928 7.49937 7.56155V8.43655C7.49937 8.5053 7.55562 8.56155 7.62437 8.56155H12.5306V9.74905C12.5306 9.85373 12.6525 9.91311 12.7337 9.84748L14.9509 8.09748C14.9659 8.08579 14.978 8.07085 14.9863 8.0538C14.9946 8.03674 14.9989 8.01802 14.9989 7.99905C14.9989 7.98008 14.9946 7.96135 14.9863 7.9443C14.978 7.92724 14.9659 7.9123 14.9509 7.90061V7.90061Z"
                fill="white"
              />
            </svg>
            Выйти
          </div>
        </div>
      ),
    },
  ];

  const logout = () => {
    setIsShowLogoutModal(false);
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('user');
    globalController.setUser(null);
    toast.success('Вы вышли из аккаунта');
    navigate(LOGIN_PATH);
  };

  const handleLanguageChange = (e: any) => {
    setSelectedKeys(e.keyPath);
    sessionStorage.setItem('language', e.key);
  };

  const handleUserDropdown = (e: any) => {
    if (e.key === 'logout') {
      setIsShowLogoutModal(true);
    }
  };

  const items = useMemo(
    () =>
      menuItems
        .filter((item) => item.name)
        .filter(
          (item) =>
            !item.roles.length ||
            user?.roles?.some((element: string) => item.roles.includes(element)),
        )
        .map((item) => ({
          label: <Link to={item.link}>{item.name}</Link>,
          key: item.link,
        })),
    [user],
  );

  useEffect(() => {
    setSelectedKeysMenu([pathname]);
  }, [pathname]);

  if (isPrint) return null;

  return (
    <div
      className="h-16 fixed left-0 top-0 right-0 bg-no-repeat flex items-center justify-center z-[255]"
      style={{
        backgroundImage: 'url(/images/logo.svg)',
        backgroundColor: '#001529',
        backgroundPosition: '50px center',
        backgroundSize: 'auto 50%',
      }}
    >
      <div
        className="absolute top-0 left-[30px] h-16 w-32"
        role={isLoginPage ? undefined : 'button'}
        tabIndex={isLoginPage ? -1 : 0}
        onClick={() => !isLoginPage && logout()}
      />
      {isShowMenu && (
        <Menu
          theme="dark"
          mode="horizontal"
          className="md:w-[530px] mx-auto h-full items-center"
          selectedKeys={selectedKeysMenu}
          onClick={(e) => setSelectedKeysMenu(e.keyPath)}
          items={items}
        />
      )}

      {false && (
        <Dropdown
          trigger={['click']}
          menu={{ items: languages, onClick: handleLanguageChange, selectedKeys }}
          className={clsx(
            'mx-4 bg-[#eee5] text-sm rounded-xl px-2 py-1 cursor-pointer min-w-[9rem]',
            !isShowMenu && 'ml-auto',
          )}
        >
          <a onClick={(e) => e.preventDefault()}>
            <div className="flex items-center justify-between gap-2">
              {languages.find((el) => el.key === selectedKeys[0])?.label ||
                selectLangText[browserLanguage] ||
                selectLangText['ru']}
              <DownOutlined />
            </div>
          </a>
        </Dropdown>
      )}

      {isShowMenu && (
        <div className="mr-[50px] text-white flex gap-6">
          <div
            className="relative cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={() => navigate(NOTIFY_PATH)}
          >
            <BellFilled style={{ fontSize: 24 }} />
            {unreadNotifications > 0 && (
              <div
                className="absolute bg-[#FF4D4F] w-[22px] h-[22px] rounded-full border border-white text-white text-xs flex items-center justify-center top-1/2 -right-3"
                style={{
                  boxShadow:
                    '0px 1px 2px -2px rgba(0, 0, 0, 0.16), 0px 3px 6px 0px rgba(0, 0, 0, 0.02), 0px 5px 13px 3px rgba(0, 0, 0, 0.09)',
                  transform: 'translateY(-50%)',
                }}
              >
                {unreadNotifications}
              </div>
            )}
          </div>
          <SettingFilled
            role="button"
            tabIndex={0}
            onClick={() => {
              setSelectedKeysMenu([]);
              navigate(SETTINGS_PATH);
            }}
            style={{ fontSize: 24, cursor: 'pointer' }}
          />

          <Dropdown
            key={JSON.stringify(user)}
            trigger={['click']}
            menu={{
              items: userMenu,
              onClick: handleUserDropdown,
              selectedKeys,
              className: '!bg-[#001529]',
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <UserOutlined className="border border-white rounded-full flex items-center justify-center cursor-pointer !w-6 !h-6" />
            </a>
          </Dropdown>
        </div>
      )}

      <Modal
        centered
        open={isShowLogoutModal}
        onCancel={() => setIsShowLogoutModal(false)}
        title="Вы действительно хотите выйти?"
        footer={
          <div className="flex items-center gap-4 w-fit ml-auto">
            <Button onClick={() => setIsShowLogoutModal(false)}>Отмена</Button>
            <Button type="primary" onClick={logout}>
              Да
            </Button>
          </div>
        }
      />
    </div>
  );
});

export default Header;
