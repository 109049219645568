import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckOutlined, CloseOutlined, HomeOutlined } from '@ant-design/icons';
import { Block } from '../../components/PageElements';
import Table from '../../components/Table';
import { roles } from './mockRolesData';
import { EMPLOYEES_PATH } from '../../utils/routes_consts';

const renderBool = (el: boolean) =>
  el ? (
    <CheckOutlined style={{ color: '#389E0D', fontSize: 16 }} />
  ) : (
    <CloseOutlined style={{ fontSize: 16 }} />
  );

export default function Roles() {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        title: 'Категория права',
        dataIndex: 'category',
      },
      {
        title: 'Право',
        dataIndex: 'name',
      },
      {
        title: 'Суперадминистратор',
        dataIndex: 'superadmin',
        render: (el: boolean) => renderBool(el),
      },
      {
        title: 'Управляющий менеджер',
        dataIndex: 'admin',
        render: (el: boolean) => renderBool(el),
      },
      {
        title: 'Менеджер',
        dataIndex: 'user',
        render: (el: boolean) => renderBool(el),
      },
      {
        title: 'Клиент',
        dataIndex: 'client',
        render: (el: boolean) => renderBool(el),
      },
    ],
    [],
  );

  return (
    <div className="my-4 mx-[50px] flex flex-col">
      <button
        onClick={() => navigate(EMPLOYEES_PATH)}
        className="opacity-45 w-fit flex gap-1 items-center"
      >
        <HomeOutlined />
        Назад
      </button>
      <h2 className="mt-2 mb-5">Роли сотрудников</h2>
      <Block className="bg-white">
        <Table
          rowKey="id"
          dataSource={roles}
          columns={columns}
          pagination={{ hideOnSinglePage: true, pageSize: 999, showSizeChanger: false }}
        />
      </Block>
    </div>
  );
}
