import React from 'react';
import clsx from 'clsx';

interface CardProps {
  data: any;
  active: boolean;
  onClick: (data: any) => void;
}

const Card = ({ data, active, onClick }: CardProps) => {
  return (
    <div
      className="flex py-4 px-3 gap-4 items-start cursor-pointer"
      style={active ? { backgroundColor: '#E6F4FF' } : {}}
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      <div className="relative">
        <img src={'/images/mock/avatar.png'} alt="" className="min-w-8 min-h-8" />
        {data?.state === 'new' && (
          <div className="-right-1 -top-1 w-[6px] h-[6px] bg-[#FF4D4F] absolute rounded-full" />
        )}
      </div>
      <div className="flex flex-col gap-1 w-full">
        <div className="text-sm font-semibold">{data.title}</div>
        <div className={clsx('opacity-45', !active && 'line-clamp-2')}>{data.body}</div>
      </div>
    </div>
  );
};

export default Card;
