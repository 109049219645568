import axios from 'axios';

import { URL_API } from '../consts';
import globalController from '../../mobx/GlobalController';

import { refreshToken } from './global';

const createAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: URL_API,
    headers: { 'Content-Type': 'application/json' },
  });

  return axiosInstance;
};

const axiosInstance = createAxiosInstance();

axiosInstance.interceptors.request.use(
  async (config) => {
    globalController.startRequest();
    const langSS = sessionStorage.getItem('language');
    const token = sessionStorage.getItem('token');

    if (langSS) {
      // config.headers.language = langSS;
    }

    // await checkAndRefreshToken(); // Проверка и обновление токена
    // const tokenDataSS = sessionStorage.getItem('token');
    // const tokenData = tokenDataSS ? JSON.parse(tokenDataSS) : '';

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    globalController.stopRequest();
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    globalController.stopRequest();
    return response;
  },
  async (error) => {
    globalController.stopRequest();

    if (error?.response?.status === 401) {
      const msg = error?.response?.data?.message || '';
      if (msg.includes('Invalid credentials.') || msg.includes('JWT Token not found')) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refreshToken');
        window.location.reload();
        return Promise.reject(error);
      }
      if (msg === 'Expired JWT Token') {
        await refreshToken();

        const newToken = sessionStorage.getItem('token');
        const originalRequest = error.config;
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      }
    } else {
      console.error('Response error', error);
    }
    globalController.handleError({ error });
    return Promise.reject(error);
  },
);

export default axiosInstance;
