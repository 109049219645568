import React from 'react';
import { CheckOutlined } from '@ant-design/icons';

export const Tag = ({ text }: { text: string }) => (
  <div
    className="bg-[#52C41A] rounded-[6px] min-h-8 px-4 flex items-center gap-2 text-white select-none"
    style={{
      boxShadow:
        '0px 5px 13px 3px rgba(0, 0, 0, 0.09), 0px 3px 6px 0px rgba(0, 0, 0, 0.02), 0px 1px 2px -2px rgba(0, 0, 0, 0.16)',
    }}
  >
    <CheckOutlined />
    {text}
  </div>
);
