import { makeAutoObservable } from 'mobx';

import { toast } from 'react-toastify';

class GlobalController {
  requestCount = 0;
  user = null;
  token = '';

  get waitForAnswer() {
    return this.requestCount > 0;
  }

  constructor() {
    makeAutoObservable(this);
  }

  startRequest = () => {
    this.requestCount += 1;
  };

  stopRequest = () => {
    if (this.requestCount > 0) {
      this.requestCount -= 1;
    }
  };

  getToken() {
    return this.token;
  }

  setToken(token: string) {
    this.token = token;
  }

  getUser(): any | null {
    return this.user;
  }

  setUser(user: any) {
    this.user = user;
  }

  handleError({ error, message }: { error?: any; message?: string }) {
    this.stopRequest();

    if (error?.response?.data?.violations) {
      error.response.data.violations.forEach(
        (el: any) => el.message && toast(el.message, { type: 'error' }),
      );
    } else {
      const msg = message || error?.response?.data?.message || error?.message || 'Ошибка сервера';
      toast(msg, { type: 'error' });
      console.error(error || msg);
    }
  }
}

const globalController = new GlobalController();
export default globalController;
