import React from 'react';
import { SVGProps } from './types';

export const SafetyCertificateFilledIcon = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    {...props}
  >
    <path
      d="M60.9539 11.9461L37.0617 3.80391C36.7734 3.70547 36.3867 3.65625 36 3.65625C35.6133 3.65625 35.2266 3.70547 34.9383 3.80391L11.0461 11.9461C10.4625 12.143 9.98438 12.818 9.98438 13.4367V47.3555C9.98438 47.9742 10.3852 48.7898 10.8703 49.1766L35.107 68.0625C35.3531 68.2523 35.6695 68.3508 35.993 68.3508C36.3164 68.3508 36.6398 68.2523 36.8789 68.0625L61.1156 49.1766C61.6008 48.7969 62.0016 47.9812 62.0016 47.3555V13.4367C62.0156 12.818 61.5375 12.15 60.9539 11.9461V11.9461ZM48.832 23.9555L33.8836 44.5359C33.7785 44.6798 33.641 44.7968 33.4821 44.8774C33.3233 44.9581 33.1477 45.0001 32.9695 45.0001C32.7914 45.0001 32.6158 44.9581 32.4569 44.8774C32.2981 44.7968 32.1606 44.6798 32.0555 44.5359L23.168 32.3016C22.9008 31.9289 23.168 31.4086 23.625 31.4086H27.5063C27.8648 31.4086 28.2094 31.5844 28.4203 31.8727L32.9695 38.1305L43.5797 23.5195C43.7906 23.2313 44.1281 23.0555 44.4937 23.0555H48.375C48.832 23.0625 49.0992 23.5828 48.832 23.9555V23.9555Z"
      fill="#52C41A"
    />
  </svg>
);
