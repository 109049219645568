import React, { useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { ROLES } from '../../utils/consts';
import { isUserAdmin, validateEmail } from '../../utils/helpers';
import globalController from '../../mobx/GlobalController';

const { Option } = Select;

interface Props {
  open: boolean | any;
  onCreate: (values: any) => void;
  onEdit?: (values: any) => void;
  onCancel: () => void;
}

const EmployeeModal = ({ open, onEdit, onCreate, onCancel }: Props) => {
  const [form] = Form.useForm();
  const user = globalController.getUser();
  const handleCreateOrEdit = () => {
    form
      .validateFields()
      .then((values) => {
        values.phone = open?.phone || '';
        // if (!values.phone) delete values.phone;
        if (open === true) {
          onCreate(values);
        } else if (typeof open === 'object' && onEdit) {
          values.id = open.id;
          onEdit(values);
        }
      })
      .catch((errorInfo) => {
        console.log('Validation failed:', errorInfo);
      });
  };

  useEffect(() => {
    if (typeof open === 'object') {
      form.setFieldsValue({
        name: open?.name || '',
        email: open?.email || '',
        role: open?.roles?.[0] || '',
      });
    } else if (open === true) {
      form.resetFields();
    }
  }, [form, open]);

  return (
    <Modal
      centered
      open={open}
      title={open !== true ? 'Редактирование' : 'Новый сотрудник'}
      okText={open !== true ? 'Сохранить' : 'Создать'}
      cancelText="Отмена"
      onCancel={onCancel}
      onOk={handleCreateOrEdit}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="ФИО Сотрудника"
          rules={[
            { required: true, message: 'Пожалуйста, введите ФИО' },
            {
              min: 1,
              max: 32,
              message: 'ФИО должна содержать от 1 до 32 символов',
            },
            {
              pattern: /^[a-zA-Zа-яА-ЯЁё\s-]*$/,
              message:
                'ФИО должна содержать только русские или латинские буквы, символ "-" и пробел',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Пожалуйста, введите email' },
            { validator: validateEmail },
            {
              max: 50,
              message: 'Максимальное количество знаков - 50',
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="role"
          label="Роль в системе"
          rules={[{ required: true, message: 'Пожалуйста, выберите роль' }]}
          className="w-1/2"
        >
          <Select disabled={!isUserAdmin(user?.roles)}>
            {ROLES.map((el) => (
              <Option value={el.name} key={el.name}>
                {el.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EmployeeModal;
