import { DownOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Modal, Popconfirm, Radio } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { COMPANY_TYPES } from '../../utils/consts';
import {
  assignCompanyToClient,
  deleteBankAccount,
  editBankAccount,
  editCompany,
  requestClientStamp,
} from '../../utils/httpServices/global';
import { Block, Row } from '../../components/PageElements';
import clsx from 'clsx';
import { useQueryClient } from 'react-query';

export const Line = ({ data, name }: { data: ReactNode; name: ReactNode }) => (
  <div className="flex items-center justify-between">
    <p>{name}</p>
    <p>{data}</p>
  </div>
);

export default function ModalStatus({
  open,
  data,
  dataCompanies,
  dataAccounts,
  onClose,
  setIsShowCreateOrgModal,
  setIsShowCreateAccModal,
  company,
  setCompany,
  refetch,
  isSmall,
  setStep,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [isStampRequested, setIsStampRequested] = useState<boolean>(false);
  const { refetchClient, refetchClients, refetchAccounts, refetchCompanies } = refetch;
  const onFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        if (data && company) {
          assignCompanyToClient(data.id, { companyId: company?.id || 0 }).then((res: any) => {
            if (res && !res?.error) {
              queryClient.invalidateQueries();
              onClose();
            }
            if (isSmall) {
              setStep(2);
            }
          });
        }
      })
      .catch((errorInfo) => {
        console.log('Failed:', errorInfo);
      });
  };

  const handleCompanyType = (index: number) => {
    if (company) {
      const values = { ...company, type: COMPANY_TYPES[index] };
      editCompany(values).then((res: any) => {
        refetchCompanies();
        setCompany(res);
        return res;
      });
    }
  };

  const handleAccount = (index: number) => {
    const item = dataAccounts?.items?.[index];
    if (item) {
      const values = { ...item, isDefaultAccount: 1, companyId: company?.id || 0 };
      editBankAccount(values).then((res: any) => {
        refetchAccounts();
        return res;
      });
    }
  };

  const handleSelect = (val: any) => {
    const organization = (dataCompanies?.items || []).find((el: any) => +el.id === +val.key);
    setCompany(organization || null);
  };

  useEffect(() => {
    if (typeof open === 'object') {
      const organization = (dataCompanies?.items || []).find((el: any) => +el.id === +open?.id);
      setCompany(organization || null);
    }
  }, [open]);

  const requestStamp = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    requestClientStamp(data.id).then(() => setIsStampRequested(true));
  };

  const showForm = () => (
    <Form
      layout="vertical"
      form={form}
      className={clsx('flex flex-col', isSmall && 'w-full')}
      name="form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      {!isSmall && <h2>Данные организации</h2>}

      <Block className="my-5">
        <div>
          <div className="flex justify-between w-full mb-4">
            <Dropdown
              menu={{
                items: (dataCompanies?.items || []).map((el: any) => ({
                  label: el.name,
                  key: el.id,
                })),
                onClick: handleSelect,
              }}
              autoFocus
              trigger={['click']}
              disabled={!dataCompanies?.items?.length}
            >
              <a
                tabIndex={0}
                onClick={(e) => e.preventDefault()}
                className="rounded-[6px] border px-4 py-1"
                style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}
              >
                <div className="inline-flex items-center justify-between w-full">
                  {company?.name || 'Список организаций'}
                  <DownOutlined style={{ marginLeft: 9 }} />
                </div>
              </a>
            </Dropdown>
            <button
              onClick={() => setIsShowCreateOrgModal(company?.type || COMPANY_TYPES[0])}
              type="button"
            >
              <PlusOutlined style={{ fontSize: 24, color: '#1677FF' }} />
            </button>
          </div>

          {company?.type && (
            <Radio.Group
              // onChange={(e) => setSelectedStatus(e.target.value)}
              onChange={(e) => !isSmall && handleCompanyType(e.target.value)}
              value={COMPANY_TYPES.findIndex((type) => type === company.type)}
              className="flex items-center gap-4 mb-4"
            >
              <Radio
                value={0}
                checked={company.type === COMPANY_TYPES[0]}
                disabled={company.type !== COMPANY_TYPES[0]}
                className="!min-w-fit"
              >
                физ. лицо
              </Radio>
              <Radio
                value={1}
                checked={company.type === COMPANY_TYPES[1]}
                className="!min-w-fit"
                disabled={company.type !== COMPANY_TYPES[1]}
              >
                ИП
              </Radio>
              <Radio
                value={2}
                checked={company.type === COMPANY_TYPES[2]}
                className="!min-w-fit"
                disabled={company.type !== COMPANY_TYPES[2]}
              >
                юр. лицо
              </Radio>
            </Radio.Group>
          )}

          {company && company.type === COMPANY_TYPES[2] && (
            <button disabled={isStampRequested} className="text-[#1677FF]" onClick={requestStamp}>
              {isStampRequested ? 'Печать запрошена' : 'Запросить печать'}
            </button>
          )}

          {company && company.type === COMPANY_TYPES[0] && (
            <div className="flex flex-col gap-3">
              <Row name="Паспорт" value={data.passport} />
              <Row name="Телефон" value={data.phone} />
              <Row name="Email" value={data.email} />
              <Row name="Должность" value={data.position} />
            </div>
          )}

          {company && company.type !== COMPANY_TYPES[0] && (
            <>
              <div className="flex flex-col gap-2 mt-3">
                <Line data={company?.name} name="Название компании" />
                <Line data={company?.inn} name="ИНН" />
                <Line data={company?.kpp} name="КПП" />
                <Line data={company?.ogrn} name="ОГРН" />
                <Line data={company?.director} name="Ген. директор" />
                <Line data={company?.address} name="Юр. адрес" />
              </div>
              <div className="flex w-full justify-between">
                <h3 className="mt-6 mb-[10px]">Реквизиты</h3>
                <button
                  type="button"
                  style={{ fontSize: 24, color: '#1677FF' }}
                  onClick={() => setIsShowCreateAccModal(company)}
                >
                  <PlusOutlined />
                </button>
              </div>
              {dataAccounts?.items?.length > 0 && <p>Счёт для договора</p>}
              <ul className="w-full flex flex-col gap-2 mt-2">
                {(dataAccounts?.items || [])?.map((el: any, i: number) => (
                  <li
                    key={JSON.stringify(el)}
                    className="flex w-full text-sm justify-between items-center"
                  >
                    <button className="flex" onClick={() => handleAccount(i)} type="button">
                      <Radio checked={el.isDefaultAccount} />
                      {el.bankName}
                    </button>
                    <Popconfirm
                      title="Удалить счёт?"
                      onConfirm={() =>
                        deleteBankAccount(el.id).then(() => {
                          refetchAccounts();
                        })
                      }
                      okText="Удалить"
                      cancelText="Отмена"
                    >
                      <button type="button">
                        <CloseOutlined style={{ opacity: 0.25 }} />
                      </button>
                    </Popconfirm>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </Block>

      {isSmall ? (
        <Form.Item className="flex justify-end items-center">
          <Button onClick={() => setStep(0)} className="mr-4">
            Назад
          </Button>
          <Button type="primary" htmlType="submit">
            Далее
          </Button>
        </Form.Item>
      ) : (
        <Form.Item className="ml-auto">
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form.Item>
      )}
    </Form>
  );

  return isSmall ? (
    showForm()
  ) : (
    <Modal
      centered
      onCancel={onClose}
      open={open}
      footer={null}
      maskClosable={false}
      getContainer={false}
    >
      {showForm()}
    </Modal>
  );
}
