export const frequencies = [
  { label: 'Ежечасно', key: 'hourly' },
  { label: 'Ежедневно', key: 'daily' },
  { label: 'Еженедельно', key: 'weekly' },
  { label: 'Ежемесячно', key: 'monthly' },
  // убрал этот вариант - бэк его не поддерживает
  // { label: 'Каждый год', key: 'yearly' },
];

export const periods = Array(7)
  .fill(0)
  .map((_, i) => ({
    label: i + 1,
    key: i + 1,
  }));

export const mockWMsg = `Quis iusto distinctio mollitia accusantium blanditiis. Repellendus consequatur omnis harum quibusdam molestiae voluptatem minima [LINK]`;

export const options = [
  { label: 'Автоматизированное', value: 'automated' },
  { label: 'Произвольное', value: 'custom' },
];
