import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { BASE_URL } from '../../utils/consts';

const PolicyPreview = () => {
  const [markdown, setMarkdown] = useState<string>('');

  useEffect(() => {
    fetch(`${BASE_URL}/api/v1/policy/company_policy`)
      .then((response) => response.text())
      .then((text) => {
        setMarkdown(JSON.parse(text).content);
      });
  }, []);

  return (
    <div className="px-20 py-10">
      <Markdown>{markdown}</Markdown>
    </div>
  );
};

export default PolicyPreview;
