import { ReactNode } from 'react';

import { toast } from 'react-toastify';

import { emailRegex, passwordRegex, passwordRequirements } from './consts';

export const uid = () => (Date.now() + Math.random().toString(36)).replaceAll('.', '');

export const convertPercentToMm = (percent: number, sizeMm: number) => (percent / 100) * sizeMm;

export const validateEmail = (rule: any, value: string) => {
  if (!emailRegex.test(value)) {
    return Promise.reject('Неверный формат почты');
  }
  return Promise.resolve();
};

export const validatePassword = (_: any, value: string) => {
  if (!value) return Promise.reject(new Error('Пожалуйста, введите пароль!'));
  if (!passwordRegex.test(value)) {
    return Promise.reject(new Error(passwordRequirements));
  }
  return Promise.resolve();
};

export const random = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const copyToClipboard = (text: string, msg: ReactNode) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast(msg, { type: 'success', autoClose: 2000 });
    })
    .catch((error) => {
      console.error('Ошибка при копировании текста', error);
    });
};

export const FormatNumber = (number: any) => {
  if (Number.isNaN(number)) return 0;
  return new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 })
    .format(number)
    .replace(',', '.');
};

export const formatNumber = (number: any) =>
  new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 }).format(number).replace(',', '.');

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const formatPhone = (phone: string) => phone.replace(/\D/g, '');

export const toFormData = (obj: any) => {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'string') formData.append(key, value);
  });
};

// Функция для форматирования даты и времени ISO => ru-RU
export const FormatDate = (date: string | number | Date) =>
  new Date(date).toLocaleString('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

// Функция для форматирования даты и времени ISO => ru-RU
export const FormatDateTime = (date: string | number | Date) =>
  new Date(date).toLocaleString('ru-RU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

export function getRandomDateWithinLastFiveYears() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const randomYear = currentYear - Math.floor(Math.random() * 5); // Генерируем случайный год в пределах последних 5 лет
  const randomMonth = Math.floor(Math.random() * 12) + 1; // Генерируем случайный месяц (от 1 до 12)
  const randomDay = Math.floor(Math.random() * 28) + 1; // Генерируем случайный день (от 1 до 28, учитывая февраль)

  const randomDate = new Date(randomYear, randomMonth - 1, randomDay); // Месяцы в JavaScript начинаются с 0
  return randomDate.toISOString(); // Возвращаем дату в формате ISO строка
}

export const isUserAdmin = (roles: string[]) => {
  if (!roles || !roles.length) {
    return false;
  }
  return roles.includes('ROLE_ADMIN');
};

export const isUserSupervisor = (roles: string[]) => {
  if (!roles || !roles.length) {
    return false;
  }
  return roles.includes('ROLE_SUPERVISOR');
};

export const isUserManager = (roles: string[]) => {
  if (!roles || !roles.length) {
    return false;
  }
  return roles.includes('ROLE_MANAGER');
};
