import { Button, Input } from 'antd';
import clsx from 'clsx';
import { SearchOutlined, InfoCircleFilled } from '@ant-design/icons';
import { useEffect, useRef } from 'react';
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import Spinner from '../Spinner';

const List = ({
  data = [],
  onClick,
  activeIdx,
  onChange,
  value,
  onSearch,
  sortDirection,
  setSortDirection,
  isLoading,
}: any) => {
  const activeElementRef = useRef<null | HTMLLIElement>(null);

  const toggleSortDirection = () => {
    setSortDirection((prev: string) => (prev === 'ASC' ? 'DESC' : prev === 'DESC' ? '' : 'ASC'));
  };

  useEffect(() => {
    if (activeElementRef.current) {
      activeElementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [activeIdx]);

  return (
    <div className="min-w-[22%] flex flex-col gap-4 relative">
      <div className="flex gap-1 items-center sticky top-0 left-0 z-[254]">
        <Input
          // className=""
          style={{ width: `calc(100% - 8px)`, zIndex: 200 }}
          value={value}
          onChange={onChange}
          addonAfter={
            <button onClick={onSearch} className="z-[250]">
              <SearchOutlined style={{ zIndex: 999 }} />
            </button>
          }
        />
        <Button
          onClick={toggleSortDirection}
          className="flex items-center"
          title={
            sortDirection === 'ASC'
              ? 'Сортировка по возрастанию'
              : sortDirection === 'DESC'
              ? 'Сортировка по убыванию'
              : 'Без сортировки'
          }
        >
          {sortDirection === 'ASC' && <SortAscendingOutlined />}
          {sortDirection === 'DESC' && <SortDescendingOutlined />}
          {!sortDirection && <UnorderedListOutlined />}
        </Button>
      </div>
      {isLoading ? (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      ) : (
        <ul className="mr-2 overflow-auto max-h-[70vh]">
          {data?.length ? (
            data?.map((el: any, i: number) => (
              <li
                ref={activeIdx === i ? activeElementRef : null}
                key={JSON.stringify(el)}
                className={clsx(
                  'px-6 py-[9px] rounded-[8px] cursor-pointer',
                  activeIdx === i && 'bg-[#E6F4FF] text-[#1677FF]',
                )}
                onClick={() => onClick && onClick(i)}
              >
                {el.name}
              </li>
            ))
          ) : (
            <p className="font-semibold ml-1 mt-6">В этом разделе нет клиентов</p>
          )}
        </ul>
      )}
    </div>
  );
};

const Block = ({ children, className, noShadow = false, ...otherProps }: any) => (
  <div
    className={clsx('h-fit p-5 rounded-[10px] relative', className)}
    style={
      noShadow
        ? {}
        : {
            boxShadow:
              '0px 5px 13px 3px rgba(0, 0, 0, 0.09), 0px 3px 6px 0px rgba(0, 0, 0, 0.02), 0px 1px 2px -2px rgba(0, 0, 0, 0.16)',
          }
    }
    {...otherProps}
  >
    {children}
  </div>
);

const Cell = ({ name, value, ...otherProps }: any) =>
  value ? (
    <div
      className="grid grid-cols-2 w-full pt-1 pb-[6px] "
      style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.06)' }}
      {...otherProps}
    >
      <div>{name}</div>
      <div>{value}</div>
    </div>
  ) : null;

const Row = ({ name, value, ...otherProps }: any) => (
  <div className="max-w-[33%]" {...otherProps}>
    <div className="opacity-25">{name}</div>
    <div className="overflow-wrap break-all">{value}</div>
  </div>
);

const BlueBox = ({ text, btnText, onClick, className }: any) => (
  <div
    className={clsx(
      'px-4 py-2 w-full flex items-center justify-between bg-[#E6F4FF] rounded-[8px] border border-[#91CAFF]',
      className,
    )}
  >
    <div className="flex gap-2 items-center">
      <InfoCircleFilled style={{ color: '#1677FF' }} />
      {text}
    </div>
    {btnText && (
      <Button onClick={onClick} type="primary">
        {btnText}
      </Button>
    )}
  </div>
);

export { List, Block, Cell, Row, BlueBox };
