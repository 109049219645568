import {
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Checkbox,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Modal,
  Radio,
  Tag,
  TimePicker,
} from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Block } from '../../components/PageElements';
import Spinner from '../../components/Spinner';
import globalController from '../../mobx/GlobalController';
import { DAYS, RQ_OPT } from '../../utils/consts';
import { isUserAdmin, isUserManager, isUserSupervisor } from '../../utils/helpers';
import { getClientsAA } from '../../utils/httpServices/global';
import {
  createNotification,
  deleteNotification,
  getNotifications,
  toggleNotification,
  updateNotification,
} from '../../utils/httpServices/notifications';
import { frequencies, options } from './data';

export default function Notifications() {
  const user = globalController.getUser();
  const [wMsgTemp, setWMsgTemp] = useState('');
  const [showSelectClients, setShowSelectClients] = useState(false);
  const [isWhatsapp, setIsWhatsapp] = useState(true);

  const [isShowEditPModal, setIsShowEditPModal] = useState<any>(null);
  const [clientIds, setClientIds] = useState<number[]>([]);
  const [pMsgTemp, setPMsgTemp] = useState<any>({});
  const [clientSearchStr, setClientSearchStr] = useState('');
  const [frequency, setFrequency] = useState<any>(null);
  const [time, setTime] = useState<any>(null);
  const [isShowEditWModal, setIsShowEditWModal] = useState(false);
  const [value, setValue] = useState(options?.[0]?.value);
  const [date, setDate] = useState<any[]>([]);
  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  const resetForm = () => {
    setValue(options?.[0]?.value);
    setClientIds([]);
    setPMsgTemp({ title: '', content: '' });
    setClientSearchStr('');
    setFrequency(null);
    setTime(null);
    setDate([]);
  };

  const { data: clientsAA } = useQuery('clientsAA', () => getClientsAA(), RQ_OPT);

  const {
    data: notifications,
    isLoading,
    refetch,
  } = useQuery('notifications', () => getNotifications());

  const handlePClick = ({ key }: { key: string }, el: any) => {
    if (key === 'edit') {
      setIsShowEditPModal(el);
    } else if (key === 'delete') {
      deleteNotification(el.id)
        .then(() => {
          refetch();
        })
        .catch((error) => console.error('deleteNotification error:', error));
    } else if (key === 'disable' || key === 'enable') {
      toggleNotification(el.id, key === 'enable')
        .then((res) => {
          refetch();
        })
        .catch((error) => console.error('toggleNotification error:', error));
    }
  };

  useEffect(() => {
    if (isShowEditPModal === true) {
      resetForm();
    } else if (typeof isShowEditPModal === 'object') {
      setValue(isShowEditPModal?.type);
      setClientIds(isShowEditPModal?.customers?.map((el: any) => el.id));
      if (isShowEditPModal?.body) {
        setPMsgTemp((prev: any) => ({ ...prev, content: isShowEditPModal.body }));
      }
      if (isShowEditPModal?.title) {
        setPMsgTemp((prev: any) => ({ ...prev, title: isShowEditPModal.title }));
      }
      if (isShowEditPModal?.schedule?.frequency) {
        setFrequency(frequencies.find((el) => el.key === isShowEditPModal.schedule.frequency));
      }
      if (isShowEditPModal?.schedule?.days) {
        const newDays = isShowEditPModal.schedule.days.map((el: string) =>
          DAYS.findIndex((day) => day.en === el),
        );
        setSelectedDays(newDays);
      }
      if (isShowEditPModal?.channels?.includes('whatsapp')) {
        setIsWhatsapp(true);
      }
      if (isShowEditPModal?.schedule?.time) {
        const today = new Date();
        const timeParts = isShowEditPModal.schedule.time.split(':');
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);
        today.setHours(hours);
        today.setMinutes(minutes);
        today.setSeconds(0);
        setTime({
          t: dayjs(today),
          timeStr: isShowEditPModal.schedule.time + ':00',
        });
      }
      if (isShowEditPModal?.schedule?.dateFrom) {
        setDate((prev) => {
          const arr = [...prev];
          arr[0] = {
            date: dayjs(isShowEditPModal.schedule.dateFrom),
            dateStr: isShowEditPModal.schedule.dateFrom,
          };
          return arr;
        });
      }
      if (isShowEditPModal?.schedule?.dateUntil) {
        setDate((prev) => {
          const arr = [...prev];
          arr[1] = {
            date: dayjs(isShowEditPModal.schedule.dateUntil),
            dateStr: isShowEditPModal.schedule.dateUntil,
          };
          return arr;
        });
      }
    }
  }, [isShowEditPModal]);

  useEffect(() => {
    setSelectedDays([]);
  }, [frequency]);

  const handleSubmitNotification = (notificationText: string) => {
    if (showSelectClients) {
      setShowSelectClients(false);
      return;
    }
    if (Object.values(pMsgTemp).some((el) => !el)) {
      toast('Заполните все поля', { type: 'warning' });
      return;
    }
    if (notificationText.length > 255) {
      toast('Слишком длинный текст', { type: 'warning' });
      return;
    }
    const dataObj: any = {
      title: pMsgTemp?.title || '',
      body: notificationText,
      channels: ['push'],
      schedule: {
        frequency: frequency.key,
        time: time?.timeStr?.slice(0, 5),
        dateFrom: date?.[0]?.dateStr,
        dateUntil: date?.[1]?.dateStr,
        enabled: true,
      },
      rule: 'unsigned_document',
    };

    if (isWhatsapp) {
      dataObj.channels.push('whatsapp');
    }

    if (value === options[1].value) {
      dataObj.customerIds = clientIds;
    }

    if (selectedDays.length > 0) {
      dataObj.schedule.days = selectedDays.map((el) => DAYS[el].en);
    }
    if (isShowEditPModal === true) {
      createNotification(dataObj)
        .then((res) => {
          setClientIds([]);
          refetch();
        })
        .catch((error: any) => {
          console.log('createNotification error:', error);
        });

      setIsShowEditPModal(false);
    } else {
      updateNotification(isShowEditPModal.id, dataObj)
        .then(() => {
          setClientIds([]);
          refetch();
        })
        .catch((error: any) => {
          console.log('updateNotification error:', error);
        });
    }

    setIsShowEditPModal(null);
    setPMsgTemp({});
  };

  const saveWhatsappNotification = (el: any, message: string) => {
    if (message.length > 255) {
      toast('Слишком длинный текст', { type: 'warning' });
      return;
    }
    const dataObj: any = {
      ...el,
      body: message,
      rule: 'unsigned_document',
    };
    updateNotification(el.id, dataObj)
      .then(() => {
        refetch();
      })
      .catch((error: any) => {
        console.log('updateNotification error:', error);
      });
  };

  return (
    <div className="mt-8 mb-4 mx-[50px] flex flex-col">
      <h1 className="text-[20px]">Настройки уведомлений и рассылок</h1>
      <div className="flex gap-5 p-5 w-full bg-white mt-3">
        <Block className={clsx('w-2/3', !notifications?.items?.length && 'min-h-[15vh] ')}>
          <div className="flex w-full justify-between mb-4">
            <h3 className="mb-2">Push-сообщения</h3>
            {(isUserAdmin(user?.roles) ||
              isUserSupervisor(user?.roles) ||
              isUserManager(user?.roles)) && (
              <button
                onClick={() => {
                  setIsShowEditPModal(true);
                  setPMsgTemp({ title: '', content: '' });
                }}
                style={{ color: '#1677FF', display: 'flex', gap: '8px', alignItems: 'center' }}
              >
                <PlusOutlined style={{ fontSize: 20 }} />
                Создать
              </button>
            )}
          </div>
          {isLoading ? (
            <Spinner fullW />
          ) : notifications?.items?.length ? (
            notifications?.items
              ?.filter((el: any) => el?.channels?.includes('push'))
              .map((el: any, i: number, arr: any[]) => (
                <React.Fragment key={el.id}>
                  <div className={'flex w-full justify-between mb-2'}>
                    <Block className="w-1/2 py-5 px-4">
                      <div className="flex gap-2 items-center">
                        <img src={'/images/SmallIcon.svg'} alt="" />
                        <div>
                          <div className="text-[11px] flex mb-1">
                            <div className="font-semibold mr-1">{el.title}</div> • {'Сейчас'}
                          </div>
                          <div className="text-[13px]">{el.body}</div>
                        </div>
                      </div>
                    </Block>
                    <div className="flex flex-col gap-2 text-sm">
                      <div>
                        <div>Тип</div>
                        <div className="opacity-25">
                          {el?.type
                            ? options.find((item) => item.value === el.type)?.label || el.type
                            : '-'}
                        </div>
                      </div>
                      <div>
                        <div>Даты</div>
                        <div className="opacity-25">
                          {el?.schedule?.dateFrom
                            ? new Date(el.schedule.dateFrom).toLocaleDateString()
                            : '-'}{' '}
                          —{' '}
                          {el?.schedule?.dateUntil
                            ? new Date(el.schedule.dateUntil).toLocaleDateString()
                            : '-'}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 text-sm">
                      <div>
                        <div>Отправка</div>
                        <div className="opacity-25">
                          {el?.schedule?.frequency
                            ? frequencies.find((item) => item.key === el.schedule.frequency)
                                ?.label || el.schedule.frequency
                            : '-'}
                          {el?.schedule?.days && (
                            <p>
                              {el?.schedule?.days
                                .map(
                                  (el: any, i: number, arr: any[]) =>
                                    `${DAYS.find((day) => day.en === el)?.ru}`,
                                )
                                .join(', ')}
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <div>Время</div>
                        <div className="opacity-25">{el?.schedule?.time || '-'}</div>
                      </div>
                    </div>
                    <Dropdown
                      placement="bottomRight"
                      menu={{
                        items: [
                          el?.schedule?.enabled
                            ? { label: 'Приостановить', key: 'disable' }
                            : { label: 'Запустить', key: 'enable' },
                          { label: 'Редактировать', key: 'edit' },
                          { label: <div className="text-[#FF4D4F]">Удалить</div>, key: 'delete' },
                        ],
                        onClick: (info) => handlePClick(info, el),
                      }}
                      className="h-fit !w-fit"
                    >
                      <MoreOutlined style={{ fontSize: 24 }} role="button" tabIndex={0} />
                    </Dropdown>
                  </div>
                  <div className="flex gap-2 items-center">
                    {el?.schedule?.enabled === false && <Tag color="orange">Приостановлено</Tag>}
                    {el?.schedule?.dateUntil && new Date(el?.schedule?.dateUntil) < new Date() && (
                      <Tag color="red">Истёк срок</Tag>
                    )}
                  </div>
                  {i !== arr.length - 1 && <Divider className="mt-4" />}
                </React.Fragment>
              ))
          ) : (
            <div className="opacity-50 text-center w-full">Ничего не найдено</div>
          )}
        </Block>
        <Block className="w-1/3">
          <h2>Настройки WhatsApp-уведомлений</h2>
          {isLoading ? (
            <Spinner fullW />
          ) : notifications?.items?.length ? (
            notifications?.items
              ?.filter((el: any) => el?.channels?.includes('whatsapp'))
              .map((el: any, i: number) => (
                <div key={JSON.stringify(el) + i}>
                  <div className="mt-5 mb-2 flex w-full justify-between">
                    <p className="opacity-45">Текст сообщения</p>
                    <EditOutlined
                      style={{ fontSize: 24, color: '#1677FF' }}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setWMsgTemp(el.body);
                        setIsShowEditWModal(el);
                      }}
                    />
                  </div>
                  <div className="w-[95%]">{el.body}</div>
                </div>
              ))
          ) : null}
        </Block>
      </div>

      <Modal
        centered
        open={!!isShowEditWModal}
        okText="Сохранить"
        title="Текст сообщения WhatsApp"
        onCancel={() => setIsShowEditWModal(false)}
        onOk={() => {
          saveWhatsappNotification(isShowEditWModal, wMsgTemp);
          setIsShowEditWModal(false);
        }}
      >
        <Input.TextArea value={wMsgTemp} onChange={(e) => setWMsgTemp(e.target.value)} rows={3} />
      </Modal>

      <Modal
        centered
        width={560}
        open={!!isShowEditPModal}
        okText={showSelectClients ? 'Применить' : 'Cоздать сообщение'}
        title={
          showSelectClients
            ? null
            : isShowEditPModal === true
            ? 'Новое пуш-сообщение'
            : 'Редактирование сообщения'
        }
        onCancel={() => {
          if (showSelectClients) {
            setClientIds([]);
            setShowSelectClients(false);
          } else {
            setIsShowEditPModal(null);
            setPMsgTemp({});
          }
        }}
        onOk={() => {
          handleSubmitNotification(pMsgTemp?.content || '');
        }}
      >
        {showSelectClients ? (
          <>
            <h3 className="text-base font-semibold">Клиенты</h3>
            <Input
              className="my-3"
              placeholder="Поиск клиентов"
              value={clientSearchStr}
              onChange={(e) => setClientSearchStr(e.target.value)}
            />
            <div className="grid grid-cols-2 gap-2">
              {(Array.isArray(clientsAA) ? clientsAA : [])
                ?.filter((el: any) =>
                  clientSearchStr === ''
                    ? true
                    : el?.name?.toLowerCase().includes(clientSearchStr.toLowerCase()),
                )
                .map((el: any, i: number) => (
                  <div
                    key={el.id}
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={(e) => {
                      if (!clientIds.includes(el.id)) {
                        setClientIds((prev) => [...prev, el.id]);
                      } else {
                        setClientIds((prev) => prev.filter((id) => id !== el.id));
                      }
                    }}
                  >
                    <Checkbox checked={clientIds.includes(el.id)} />
                    <div>{el.name}</div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <>
            <h3 className="mt-4 mb-2">Тип сообщения</h3>
            <Radio.Group
              options={options}
              onChange={(e) => setValue(e?.target?.value)}
              value={value}
              optionType="button"
              buttonStyle="solid"
              className="text-center"
            />

            <h3 className="mt-4 mb-2">Сообщение</h3>
            <div className="grid-cols-2 grid gap-3">
              <div>
                <h3 className="mb-1 opacity-25">ЗАГОЛОВОК</h3>
                <Input.TextArea
                  value={pMsgTemp?.title || ''}
                  onChange={(e) => setPMsgTemp((prev: any) => ({ ...prev, title: e.target.value }))}
                  rows={2}
                />
              </div>
              <div>
                <h3 className="mb-1 opacity-25">ТЕКСТ</h3>
                <Input.TextArea
                  value={pMsgTemp?.content || ''}
                  onChange={(e) =>
                    setPMsgTemp((prev: any) => ({ ...prev, content: e.target.value }))
                  }
                  rows={2}
                />
              </div>
            </div>
            <div
              className="flex gap-2 items-center"
              role="button"
              tabIndex={0}
              onClick={() => setIsWhatsapp((prev) => !prev)}
            >
              <Checkbox checked={isWhatsapp} />
              Дублировать в WhatsApp
            </div>

            <h3 className="mt-4 mb-2">Отправка</h3>
            <div className="grid-cols-2 grid gap-3">
              <div>
                <h3 className="mb-1 opacity-25">ДАТА СТАРТА</h3>
                <DatePicker
                  format={'DD.MM.YYYY'}
                  value={date?.[0]?.date}
                  onChange={(date, dateStr) => {
                    setDate((prev) => {
                      const arr = [...prev];
                      arr[0] = { date, dateStr };
                      return arr;
                    });
                  }}
                />
              </div>
              <div>
                <h3 className="mb-1 opacity-25">ДАТА ОКОНЧАНИЯ</h3>
                <DatePicker
                  format={'DD.MM.YYYY'}
                  value={date?.[1]?.date}
                  onChange={(date, dateStr) => {
                    setDate((prev) => {
                      const arr = [...prev];
                      arr[1] = { date, dateStr };
                      return arr;
                    });
                  }}
                />
              </div>
            </div>
            <div className="grid-cols-2 grid gap-3 mt-4">
              <div>
                <h3 className="mb-1 opacity-25 flex gap-2">
                  ВРЕМЯ <QuestionCircleOutlined />
                </h3>
                <TimePicker
                  className="min-w-[11rem] cursor-pointer"
                  value={time?.t}
                  onChange={(t, timeStr) => setTime({ t, timeStr })}
                  allowClear
                />
              </div>
              <div>
                <h3 className="mb-1 opacity-25">ЧАСТОТА</h3>
                <div className="w-3/4 mb-1 cursor-pointer">
                  <Dropdown
                    menu={{
                      items: frequencies,
                      onClick: (info) =>
                        setFrequency(frequencies.find((el) => el.key === info.key)),
                    }}
                    trigger={['click']}
                  >
                    <a
                      tabIndex={0}
                      onClick={(e) => e.preventDefault()}
                      className="rounded-[6px] border px-4 py-1 h-8 flex items-center"
                      style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}
                    >
                      <div
                        className={clsx(
                          'inline-flex items-center justify-between w-full',
                          !frequency?.label && 'opacity-25',
                        )}
                      >
                        {frequency?.label || 'Выберите частоту'}
                        <DownOutlined />
                      </div>
                    </a>
                  </Dropdown>
                </div>
                {frequency?.key === 'weekly' && (
                  <div
                    className="flex flex-wrap max-w-full gap-x-[6px] gap-y-1 p-1 rounded-[6px]"
                    // style={{ border: 'rgba(0,0,0,.15) solid 1px' }}
                  >
                    {DAYS.map((day, i) => (
                      <button
                        onClick={() =>
                          setSelectedDays((prev) => {
                            if (prev.includes(i)) {
                              return prev.filter((el) => el !== i);
                            } else {
                              return [...prev, i];
                            }
                          })
                        }
                        className="py-1 px-2 flex items-center gap-1 rounded-[4px] hover:shadow-md transition-all cursor-pointer"
                        key={i}
                        style={{
                          border: '1px solid rgba(0,0,0,.15)',
                          backgroundColor: selectedDays.includes(i) ? '#1677FF' : '#fff',
                          color: selectedDays.includes(i) ? '#fff' : '#000',
                        }}
                      >
                        {day.ru}
                        {/* <CloseOutlined style={{ fontSize: 12, opacity: 0.45 }} /> */}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {!showSelectClients && value === options[1].value && (
              <div>
                <div className="flex w-full justify-between">
                  <h3 className="mb-1 opacity-25">Клиенты</h3>

                  <button
                    onClick={() => setShowSelectClients(true)}
                    style={{ color: '#1677FF', display: 'flex', gap: '8px', alignItems: 'center' }}
                  >
                    <PlusOutlined style={{ fontSize: 20 }} />
                    Добавить
                  </button>
                </div>
                {(Array.isArray(clientsAA) ? clientsAA : [])
                  ?.filter((el: any) => clientIds.includes(el.id))
                  .map((el: any, i: number) => (
                    <Tag key={el.id}>
                      <button
                        className="flex items-center gap-1"
                        onClick={() => setClientIds((prev) => prev.filter((id) => id !== el.id))}
                      >
                        {el.name}
                        <CloseOutlined style={{ fontSize: 12 }} />
                      </button>
                    </Tag>
                  ))}
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  );
}
