import axios from 'axios';

import { MD_URL_API } from '../consts';

const createAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: MD_URL_API,

    headers: { 'Content-Type': 'application/json' },
  });

  return axiosInstance;
};

const MDAxiosInstance = createAxiosInstance();


export default MDAxiosInstance;
