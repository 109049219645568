import React from 'react';
import { SVGProps } from './types';

export const CheckCircleOutlinedIcon = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    {...props}
  >
    <path
      d="M49.1458 24.8203H45.8481C45.1309 24.8203 44.4489 25.1648 44.027 25.7555L32.9738 41.0836L27.9676 34.1367C27.5457 33.5531 26.8707 33.2016 26.1465 33.2016H22.8488C22.3918 33.2016 22.1246 33.7219 22.3918 34.0945L31.1527 46.2445C31.3597 46.5334 31.6325 46.7688 31.9486 46.9312C32.2647 47.0935 32.615 47.1782 32.9703 47.1782C33.3257 47.1782 33.6759 47.0935 33.992 46.9312C34.3081 46.7688 34.5809 46.5334 34.7879 46.2445L49.5958 25.7133C49.87 25.3406 49.6028 24.8203 49.1458 24.8203Z"
      fill="#52C41A"
    />
    <path
      d="M36 4.5C18.6047 4.5 4.5 18.6047 4.5 36C4.5 53.3953 18.6047 67.5 36 67.5C53.3953 67.5 67.5 53.3953 67.5 36C67.5 18.6047 53.3953 4.5 36 4.5ZM36 62.1562C21.5578 62.1562 9.84375 50.4422 9.84375 36C9.84375 21.5578 21.5578 9.84375 36 9.84375C50.4422 9.84375 62.1562 21.5578 62.1562 36C62.1562 50.4422 50.4422 62.1562 36 62.1562Z"
      fill="#52C41A"
    />
  </svg>
);
