import { Button, Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import { validateEmail } from '../../utils/helpers';

export default function ModalEditClient({ open, data, onEdit, onClose }: any) {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        if (onEdit) onEdit(data.id, values);
      })
      .catch((errorInfo) => {
        console.log('Failed:', errorInfo);
      });
  };

  // const handleValuesChange = (changedValues: any, allValues: any) => {
  // setFormValues(allValues);
  // };

  useEffect(() => {
    if (data)
      form.setFieldsValue({
        name: data.name,
        passport: data.passport,
        phone: data.phone,
        email: data.email,
        position: data.position,
      });
  }, [data]);

  return (
    <Modal centered onCancel={onClose} open={open} footer={null}>
      <Form
        layout="vertical"
        form={form}
        className="flex flex-col"
        name="form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onValuesChange={handleValuesChange}
      >
        <h2 className="mb-5">Данные клиента</h2>
        <Form.Item
          label="ФИО"
          name="name"
          rules={[
            { required: true, message: 'Пожалуйста, введите Ф.И.О.' },

            {
              min: 1,
              max: 32,
              message: 'ФИО должна содержать от 1 до 32 символов',
            },
            {
              pattern: /^[a-zA-Zа-яА-ЯЁё\s-]*$/,
              message:
                'ФИО должна содержать только русские или латинские буквы, символ "-" и пробел',
            },
          ]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          label="Паспорт"
          name="passport"
          rules={[
            { required: true, message: 'Пожалуйста, введите паспортные данные' },
            {
              max: 20,
              message: 'Максимальное количество знаков - 20',
            },
            {
              pattern: /^[a-zA-Z0-9]*$/,
              message: 'Поле должно содержать только цифры и буквы',
            },
          ]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          label="Телефон"
          name="phone"
          rules={[
            { required: true, message: 'Пожалуйста, введите номер телефон' },
            {
              max: 12,
              message: 'Максимальное количество знаков - 12',
            },
          ]}
        >
          <Input allowClear />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { validator: validateEmail },
            {
              max: 50,
              message: 'Максимальное количество знаков - 50',
            },
          ]}
        >
          <Input allowClear />
        </Form.Item>

        <Form.Item
          label="Должность"
          name="position"
          rules={[{ max: 32, message: 'Максимальное количество знаков - 32' }]}
        >
          <Input allowClear />
        </Form.Item>

        <Form.Item className="ml-auto">
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
