import axiosInstance from '.';

export const getNotifications = async () => {
  try {
    const response = await axiosInstance.get(`/customer-notification?perPage=999`);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const getNotification = async (id) => {
  try {
    if (!id) throw new Error('id is required');
    const response = await axiosInstance.get(`/customer-notification/${id}`);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const toggleNotification = async (id, enable) => {
  try {
    if (!id) throw new Error('id is required');
    if (typeof enable !== 'boolean') throw new Error('incorrect action');
    const response = await axiosInstance.patch(
      `/customer-notification/${id}/${enable ? 'enable' : 'disable'}`,
    );
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const createNotification = async (data) => {
  try {
    const response = await axiosInstance.post('/customer-notification', data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const updateNotification = async (id, data) => {
  try {
    const response = await axiosInstance.put(`/customer-notification/${id}`, data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const deleteNotification = async (id) => {
  try {
    const response = await axiosInstance.delete(`/customer-notification/${id}`);
    return response?.data;
  } catch (error) {
    return { error };
  }
};
