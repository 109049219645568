import React, { useEffect, useMemo, useState } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  createCompany,
  deleteCompany,
  editCompany,
  getCompanies,
} from '../../utils/httpServices/global';
import Table from '../../components/Table';
import { Form, Input, Modal, Popconfirm, Button, Radio } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { COMPANY_TYPES, COMPANY_TYPES_NAMES } from '../../utils/consts';
import { showStatus } from '../Doc/utils';
import { Block } from '../../components/PageElements';
import { isUserAdmin, isUserSupervisor, validateEmail } from '../../utils/helpers';
import globalController from '../../mobx/GlobalController';
import { CLIENTS_PATH } from '../../utils/routes_consts';

const perPage = 10;

export default function DB() {
  const user = globalController.getUser();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchStr, setSearchStr] = useState('');
  const [isShowModal, setIsShowModal] = useState<any>(false);
  const [isEdit, setIsEdit] = useState<any>(false);
  const [selectedValue, setSelectedValue] = useState(0);

  const { data, isLoading, refetch } = useQuery(['companies', page, searchStr], () =>
    getCompanies({ page, perPage }),
  );

  const pagination = useMemo(
    () => ({
      hideOnSinglePage: true,
      showSizeChanger: false,
      pageSize: data?.pagination?.perPage || perPage,
      current: data?.pagination?.page || 1,
      total: data?.pagination?.totalCount || 0,
      onChange: (page: number) => setPage(page),
    }),
    [data],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Организация / ФИО',
        dataIndex: 'name',
      },
      {
        title: 'Дата создания',
        dataIndex: 'createdAt',
        render: (date: string) => <>{date ? new Date(date).toLocaleDateString() : ''}</>,
      },
      {
        title: 'Действия',
        dataIndex: 'id',
        render: (id: any, row: any) => (
          <div
            className="flex gap-4 items-center"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <EditOutlined
              role="button"
              tabIndex={0}
              style={{ fontSize: 24, color: '#1677FF' }}
              onClick={() => {
                setIsShowModal(row);
                setIsEdit(true);
              }}
              title="Редактировать"
            />

            {(isUserAdmin(user?.roles) || isUserSupervisor(user?.roles)) && (
              <Popconfirm
                title="Удалить запись?"
                onConfirm={() => {
                  deleteCompany(id).then(() => refetch());
                }}
                okText="Удалить"
                cancelText="Отмена"
              >
                <button title="Удалить" type="button">
                  <DeleteOutlined style={{ fontSize: 24, color: '#FF4D4F' }} />
                </button>
              </Popconfirm>
            )}
          </div>
        ),
      },
    ],
    [refetch],
  );

  const onClose = () => setIsShowModal(false);

  const onFinish = (values: any) => {
    if (isShowModal === true) {
      const eValues = { ...values, type: COMPANY_TYPES[selectedValue] };
      createCompany(eValues).then((res: any) => {
        refetch();
        setIsShowModal(false);
        setIsEdit(false);
      });
    } else {
      const eValues = { ...values, id: isShowModal.id, type: isShowModal.type };

      editCompany(eValues).then((res: any) => {
        refetch();
        setIsEdit(false);
        setIsShowModal(false);
      });
    }
  };

  useEffect(() => {
    if (typeof isShowModal === 'object') {
      form.setFieldsValue({ ...isShowModal });
    }
  }, [isShowModal]);

  return (
    <>
      <div className="my-4 mx-[50px] flex flex-col">
        <button
          onClick={() => navigate(CLIENTS_PATH)}
          className="opacity-45 w-fit flex gap-1 items-center select-none"
        >
          <HomeOutlined />
          Назад
        </button>

        <h1 className="text-[20px] mt-1">База данных</h1>
        <p className="mb-4">Здесь хранятся внесённые ранее данные организаций и физ. лиц</p>
        {data?.items?.length > 0 ? (
          <Table
            loading={isLoading}
            rowKey="id"
            dataSource={data?.items}
            columns={columns}
            pagination={pagination}
            onRow={(record: any, rowIndex: any) => ({
              onClick: (e: any) => {
                e?.stopPropagation();
                setIsShowModal(record);
              },
            })}
          />
        ) : (
          <Block className="flex flex-col items-center justify-center min-h-[30vh]">
            <h3 className="text-base">Здесь пока ничего нет</h3>
            <p className="text-sm opacity-45">Но появятся карточки клиентов и организаций</p>
            <Button
              type="primary"
              onClick={() => {
                setIsShowModal(true);
                setIsEdit(true);
              }}
              className="mt-4"
            >
              Добавить
            </Button>
          </Block>
        )}
      </div>

      {!!isShowModal && isEdit && (
        <Modal
          centered
          onCancel={() => {
            form.resetFields();
            setIsShowModal(false);
            setIsEdit(false);
          }}
          open={!!isShowModal && isEdit}
          footer={null}
          maskClosable={false}
          getContainer={false}
          title={isShowModal === true ? 'Создать' : 'Редактировать'}
        >
          <Radio.Group
            onChange={(e) => {
              isShowModal === true && setSelectedValue(e.target.value);
            }}
            value={
              isShowModal === true
                ? selectedValue
                : COMPANY_TYPES.findIndex((el) => el === isShowModal?.type)
            }
            className="flex items-center gap-4 my-4 w-full"
          >
            <Radio
              value={0}
              className="!min-w-fit"
              disabled={isShowModal !== true && isShowModal?.type !== COMPANY_TYPES[0]}
            >
              {COMPANY_TYPES_NAMES[COMPANY_TYPES[0]]}
            </Radio>
            <Radio
              value={1}
              className="!min-w-fit"
              disabled={isShowModal !== true && isShowModal?.type !== COMPANY_TYPES[1]}
            >
              {COMPANY_TYPES_NAMES[COMPANY_TYPES[1]]}
            </Radio>
            <Radio
              value={2}
              className="!min-w-fit"
              disabled={isShowModal !== true && isShowModal?.type !== COMPANY_TYPES[2]}
            >
              {COMPANY_TYPES_NAMES[COMPANY_TYPES[2]]}
            </Radio>
          </Radio.Group>
          <Form
            form={form}
            layout="horizontal"
            className="flex flex-col mt-4"
            name="form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            {isShowModal?.type === COMPANY_TYPES[0] ? (
              <>
                <Form.Item
                  label="ФИО"
                  name="name"
                  rules={[
                    { required: true, message: 'Пожалуйста, введите Ф.И.О.' },
                    {
                      min: 1,
                      max: 32,
                      message: 'ФИО должна содержать от 1 до 32 символов',
                    },
                    {
                      pattern: /^[a-zA-Zа-яА-ЯЁё\s-]*$/,
                      message:
                        'ФИО должна содержать только русские или латинские буквы, символ "-" и пробел',
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  label="Паспорт"
                  name="passport"
                  rules={[
                    { required: true, message: 'Пожалуйста, введите паспортные данные' },
                    {
                      max: 20,
                      message: 'Максимальное количество знаков - 20',
                    },
                    {
                      pattern: /^[a-zA-Z0-9]*$/,
                      message: 'Поле должно содержать только цифры и буквы',
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  label="Телефон"
                  name="phone"
                  rules={[
                    { required: true, message: 'Пожалуйста, введите номер телефон' },
                    {
                      max: 12,
                      message: 'Максимальное количество знаков - 12',
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { validator: validateEmail },
                    {
                      max: 50,
                      message: 'Максимальное количество знаков - 50',
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>

                <Form.Item
                  label="Должность"
                  name="position"
                  rules={[{ max: 32, message: 'Максимальное количество знаков - 32' }]}
                >
                  <Input allowClear />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  labelCol={{ style: { minWidth: 170 } }}
                  label="Название компании"
                  name="name"
                  rules={[{ required: true, message: 'Пожалуйста, введите название компании' }]}
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  labelCol={{ style: { minWidth: 170 } }}
                  label="ИНН"
                  name="inn"
                  rules={[
                    { required: true, message: 'Пожалуйста, введите ИНН' },
                    {
                      len: 10,
                      message: 'ИНН должен состоять из 10 цифр',
                    },
                    {
                      pattern: /^\d{10}$/,
                      message: 'ИНН должен содержать только цифры',
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  labelCol={{ style: { minWidth: 170 } }}
                  label="КПП"
                  name="kpp"
                  rules={[
                    { required: true, message: 'Пожалуйста, введите КПП' },
                    {
                      len: 9,
                      message: 'КПП должен состоять из 9 цифр',
                    },
                    {
                      pattern: /^\d{9}$/,
                      message: 'КПП должен содержать только цифры',
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>

                <Form.Item
                  labelCol={{ style: { minWidth: 170 } }}
                  label="ОГРН"
                  name="ogrn"
                  rules={[
                    { required: true, message: 'Пожалуйста, введите ОГРН' },
                    {
                      len: 13,
                      message: 'ОГРН должен состоять из 13 цифр',
                    },
                    {
                      pattern: /^\d{13}$/,
                      message: 'ОГРН должен содержать только цифры',
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>

                <Form.Item
                  labelCol={{ style: { minWidth: 170 } }}
                  label="Ген. директор"
                  name="director"
                  rules={[
                    { required: true, message: 'Пожалуйста, введите ФИО директора' },
                    {
                      min: 1,
                      max: 32,
                      message: 'ФИО должна содержать от 1 до 32 символов',
                    },
                    {
                      pattern: /^[a-zA-Zа-яА-ЯЁё\s-]*$/,
                      message:
                        'ФИО должна содержать только русские или латинские буквы, символ "-" и пробел',
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>

                <Form.Item
                  labelCol={{ style: { minWidth: 170 } }}
                  label="Юридический адрес"
                  name="address"
                  rules={[{ required: true, message: 'Пожалуйста, введите юридический адрес' }]}
                >
                  <Input.TextArea allowClear />
                </Form.Item>
              </>
            )}

            <Form.Item className="ml-auto">
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}

      {!!isShowModal && !isEdit ? (
        <Modal centered onCancel={onClose} open={!!isShowModal} footer={null} title="Организация">
          {/* {showStatus({ dataClient: isShowModal, dataAccounts })} */}
          {showStatus({ dataClient: isShowModal })}
          <div className="flex items-center gap-4 w-fit ml-auto mt-4">
            <Button onClick={() => setIsEdit(true)}>Редактировать</Button>
            <Button type="primary" onClick={onClose}>
              Закрыть
            </Button>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
