const mockRolesData = [
  {
    category: 'Управление клиентами',
    name: 'Распределение клиентов между менеджерами',
    superadmin: true,
    admin: true,
    user: false,
    client: false,
  },
  {
    category: 'Управление клиентами',
    name: 'Верификация клиента',
    superadmin: true,
    admin: true,
    user: false,
    client: false,
  },
  {
    category: 'Управление клиентами',
    name: 'Просмотр списка клиентов',
    superadmin: true,
    admin: true,
    user: true,
    client: false,
  },
  {
    category: 'Управление клиентами',
    name: 'Создание клиента',
    superadmin: true,
    admin: true,
    user: false,
    client: false,
  },
  {
    category: 'Управление клиентами',
    name: 'Редактирование существующего клиента',
    superadmin: true,
    admin: true,
    user: true,
    client: false,
  },
  {
    category: 'Управление клиентами',
    name: 'Удаление клиента',
    superadmin: true,
    admin: true,
    user: false,
    client: false,
  },
  {
    category: 'Управление клиентами',
    name: 'Перевод клиента в архив',
    superadmin: true,
    admin: true,
    user: false,
    client: false,
  },
  {
    category: 'Управление договорами клиентов',
    name: 'Создание договора',
    superadmin: true,
    admin: true,
    user: true,
    client: false,
  },
  {
    category: 'Управление договорами клиентов',
    name: 'Редактирование существующих договоров',
    superadmin: true,
    admin: true,
    user: true,
    client: false,
  },
  {
    category: 'Управление договорами клиентов',
    name: 'Просмотр существующих договоров',
    superadmin: true,
    admin: true,
    user: true,
    client: false,
  },
  {
    category: 'Управление договорами клиентов',
    name: 'Отзыв неподписанных документов',
    superadmin: true,
    admin: true,
    user: true,
    client: false,
  },
  {
    category: 'Управление сотрудниками',
    name: 'Назначение роли',
    superadmin: true,
    admin: false,
    user: false,
    client: false,
  },
  {
    category: 'Управление сотрудниками',
    name: 'Просмотр',
    superadmin: true,
    admin: true,
    user: false,
    client: false,
  },
  {
    category: 'Управление сотрудниками',
    name: 'Удаление',
    superadmin: true,
    admin: false,
    user: false,
    client: false,
  },
  {
    category: 'Управление сотрудниками',
    name: 'Редактирование',
    superadmin: true,
    admin: true,
    user: false,
    client: false,
  },
  {
    category: 'Управление сотрудниками',
    name: 'Создание нового сотрудника',
    superadmin: true,
    admin: false,
    user: false,
    client: false,
  },
  {
    category: 'Отправка кастомных сообщений по каналу WhatsApp',
    name: 'Отправка сообщений определенным клиентам',
    superadmin: true,
    admin: true,
    user: true,
    client: false,
  },
  {
    category: 'Отправка кастомных сообщений по каналу WhatsApp',
    name: 'Отправка сообщений всем клиентам',
    superadmin: true,
    admin: true,
    user: false,
    client: false,
  },
  {
    category: 'Управление настройками',
    name: 'Добавление карточки организации',
    superadmin: true,
    admin: false,
    user: false,
    client: false,
  },
  {
    category: 'Управление настройками',
    name: 'Редактирование карточки организации',
    superadmin: true,
    admin: true,
    user: false,
    client: false,
  },
  {
    category: 'Управление настройками',
    name: 'Удаление карточки организации',
    superadmin: true,
    admin: false,
    user: false,
    client: false,
  },
  {
    category: 'Управление настройками',
    name: 'Загрузка Terms & Conditions',
    superadmin: true,
    admin: false,
    user: false,
    client: false,
  },
];

const roles = mockRolesData.map((el, i) => ({ ...el, id: i }));

export { roles };
