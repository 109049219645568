import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Popconfirm } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import Table from '../../components/Table';
import { FormatDate, isUserAdmin, isUserSupervisor } from '../../utils/helpers';
import { createUser, deleteUser, editUser, getUsers } from '../../utils/httpServices/global';
import EmployeeModal from './EmployeeModal';
import globalController from '../../mobx/GlobalController';
import { ReadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ROLES } from '../../utils/consts';
import { ROLES_PATH } from '../../utils/routes_consts';

const Employees = observer(() => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user: any = globalController.getUser();
  const [currentPage, setCurrentPage] = useState(1);

  const { isLoading, error, data, refetch } = useQuery(
    ['users', currentPage],
    () => getUsers(currentPage),
    { staleTime: 300 * 1000 },
  );
  const items = data?.items || [];
  const pagination = data?.pagination || {};

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleCreate = (values: any) => {
    createUser(values)
      .then((res) => {
        if (res.error) {
          console.log(error);
          // globalController.handleError(res);
        } else {
          toast.success('Сотрудник успешно добавлен');
          refetch();
          closeModal();
        }
      })
      .catch((error: any) => {
        console.log(error);
        // globalController.handleError(error);
      });
  };

  const handleEdit = async (data: any) => {
    if (typeof data !== 'object') {
      toast.error('Ошибка редактирования', { type: 'error' });
      return;
    }
    const { id, ...otherData } = data;
    editUser(id, otherData)
      .then((res) => {
        if (res.error) {
          console.log(error);
          // globalController.handleError(res);
        } else {
          refetch();
          closeModal();
        }
      })
      .catch((error: any) => {
        console.log(error);
        // globalController.handleError(error);
      });
  };

  const handleDelete = useCallback(
    (id: number) => {
      deleteUser(id)
        .then((res) => {
          if (res.error) {
            globalController.handleError(res);
          } else {
            refetch();
          }
        })
        .catch((error: any) => {
          globalController.handleError(error);
        });
    },
    [refetch],
  );
  const columns = useMemo(
    () => [
      {
        title: 'ФИО',
        dataIndex: 'name',
      },
      {
        title: 'Почта',
        dataIndex: 'email',
      },
      // {
      //   title: 'Номер телефона',
      //   dataIndex: 'phone',
      // },
      {
        title: 'Роль',
        dataIndex: 'roles',
        render: (roles: any[]) => ROLES.find((el) => el.name === roles?.[0])?.title,
      },
      {
        title: 'Дата создания',
        dataIndex: 'createdAt',
        render: (date: string) => FormatDate(date),
      },
      {
        title: 'Действия',
        dataIndex: 'id',
        render: (id: number, row: any) => (
          <div
            className="gap-6"
            style={{ display: user?.id && user?.id !== row.id ? 'flex' : 'none' }}
          >
            {(isUserAdmin(user?.roles) || isUserSupervisor(user?.roles)) && (
              <button onClick={() => setIsModalOpen(row)}>
                <EditOutlined
                  style={{ fontSize: 24, color: '#1677FF' }}
                  title="Редактировать сотрудника"
                />
              </button>
            )}
            {isUserAdmin(user?.roles) && (
              <Popconfirm
                title="Удалить сотрудника"
                description={
                  <div className="max-w-[24rem]">
                    Данный сотрудник лишится доступа к CRM-системе, но его ФИО сохранится в
                    карточках клиентов до изменения
                  </div>
                }
                onConfirm={() => handleDelete(id)}
                okText="Удалить"
                cancelText="Отмена"
              >
                <button>
                  <DeleteOutlined
                    style={{ fontSize: 24, color: '#FF4D4F' }}
                    title="Удалить сотрудника"
                  />
                </button>
              </Popconfirm>
            )}
          </div>
        ),
      },
    ],
    [handleDelete, user],
  );

  return (
    <div className="my-4 mx-[50px] flex flex-col">
      <div className="w-full flex justify-between">
        <div>
          <h1 className="text-[20px]">Сотрудники</h1>
          <p
            className="text-[#1677FF] inline-flex gap-2 items-center cursor-pointer"
            role="link"
            tabIndex={0}
            onClick={() => navigate(ROLES_PATH)}
          >
            <ReadOutlined />
            Роли сотрудников
          </p>
        </div>
        {isUserAdmin(user?.roles) && (
          <Button type="primary" className="w-[196px] h-10" onClick={openModal}>
            Добавить
          </Button>
        )}
      </div>
      <Table
        key={JSON.stringify(user)}
        loading={isLoading}
        rowKey="id"
        dataSource={items}
        columns={columns}
        className="my-4"
        pagination={{
          hideOnSinglePage: true,
          current: currentPage,
          pageSize: pagination.perPage || 20,
          total: pagination.totalCount || 0,
          showSizeChanger: false,
        }}
        isLoading={isLoading}
        onChange={(e: any) => setCurrentPage(e.current)}
      />
      <EmployeeModal
        open={isModalOpen}
        onCancel={closeModal}
        onCreate={handleCreate}
        onEdit={handleEdit}
      />
    </div>
  );
});

export default Employees;
