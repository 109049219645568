import { Radio } from 'antd';
import { Row } from '../../components/PageElements';
import { COMPANY_TYPES } from '../../utils/consts';
import { Line } from '../Clients/ModalStatus';

export const showStatus = ({ dataClient, dataAccounts, noBlock }: any) => {
  const type = dataClient?.company?.type || dataClient?.type;
  const name = dataClient?.company?.name || dataClient?.name;
  const inn = dataClient?.company?.inn || dataClient?.inn;
  const kpp = dataClient?.company?.kpp || dataClient?.kpp;
  const ogrn = dataClient?.company?.ogrn || dataClient?.ogrn;
  const director = dataClient?.company?.director || dataClient?.director;
  const address = dataClient?.company?.address || dataClient?.address;

  const passport = dataClient?.company?.passport || dataClient?.passport;
  const phone = dataClient?.company?.phone || dataClient?.phone;
  const email = dataClient?.company?.email || dataClient?.email;
  const position = dataClient?.company?.position || dataClient?.position;

  if (!dataClient || !type) return null;

  return (
    <>
      <Radio.Group
        // onChange={(e) => setSelectedStatus(e.target.value)}
        value={COMPANY_TYPES.findIndex((ttype) => ttype === type)}
        className="flex items-center gap-4 mb-4"
      >
        <Radio
          value={0}
          checked={type === COMPANY_TYPES[0]}
          disabled={type !== COMPANY_TYPES[0]}
          className="!min-w-fit"
        >
          физ. лицо
        </Radio>
        <Radio
          value={1}
          checked={type === COMPANY_TYPES[1]}
          disabled={type !== COMPANY_TYPES[1]}
          className="!min-w-fit"
        >
          ИП
        </Radio>
        <Radio
          value={2}
          checked={type === COMPANY_TYPES[2]}
          disabled={type !== COMPANY_TYPES[2]}
          className="!min-w-fit"
        >
          юр. лицо
        </Radio>
      </Radio.Group>

      {type === COMPANY_TYPES[0] ? (
        <div className="flex flex-col gap-3">
          <Row name="Паспорт" value={passport} />
          <Row name="Телефон" value={phone} />
          <Row name="Email" value={email} />
          <Row name="Должность" value={position} />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-2 mt-3">
            <Line data={name} name="Название компании" />
            <Line data={inn} name="ИНН" />
            <Line data={kpp} name="КПП" />
            <Line data={ogrn} name="ОГРН" />
            <Line data={director} name="Ген. директор" />
            <Line data={address} name="Юр. адрес" />
          </div>
          {dataAccounts?.items?.length > 0 && (
            <div className="flex w-full justify-between">
              <h3 className="mt-6 mb-[10px]">Реквизиты</h3>
            </div>
          )}
          {dataAccounts?.items?.length > 0 && <p>Счёт для договора</p>}
          <ul className="w-full flex flex-col gap-2 mt-2">
            {(dataAccounts?.items || [])?.map((el: any) => (
              <li
                key={JSON.stringify(el)}
                className="flex w-full text-sm justify-between items-center"
              >
                <button className="flex disabled:opacity-40" disabled={!el.isDefaultAccount}>
                  <Radio checked={el.isDefaultAccount} disabled={!el.isDefaultAccount} />
                  {el.bankName}
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};
