import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import clsx from 'clsx';

interface Props {
  sizePx?: number;
  fullW?: boolean;
  className?: string;
}

export default function Spinner(props: Props) {
  const { sizePx = 24, fullW = false, className } = props;

  return (
    <div className={clsx(fullW && 'w-full flex justify-center items-center', className)}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: sizePx }} spin />} />
    </div>
  );
}
