export function openLinkForPrint(path: string) {
  // Получаем полный URL текущего домена
  const fullUrl = `${window.location.origin}${path}`;

  // Открываем новую вкладку с URL
  const newWindow = window.open(fullUrl, '_blank');

  // Когда новая вкладка загрузится, вызываем диалог печати
  if (newWindow) {
    newWindow.onload = function () {
      newWindow.print();
    };
  }
}
