export const LOGIN_PATH = '/login';
export const RECOVER_PASSWORD_PATH = '/forgot-password';
export const NEW_PASSWORD_PATH = '/new-password';
export const RESET_PASSWORD_PATH = '/reset-password';
export const EMPLOYEES_PATH = '/employees';
export const ROLES_PATH = '/employees/roles';
export const CLIENTS_PATH = '/clients';
export const SETTINGS_PATH = '/settings';
export const LICENSE_PATH = '/license_agreement';
export const POLICY_PATH = '/privacy_policy';
export const AGREEMENT_PATH = '/user_agreement';
export const DOC_INFO_PATH = '/doc/:id';
export const DOCS_PATH = '/docs';
export const NOTIFICATIONS_PATH = '/notifications';
export const NOTIFY_PATH = '/notify';
export const UPLOAD_INFO_PATH = '/upload/:id';
export const UPLOADS_PATH = '/upload';
export const DB_PATH = '/db';
export const ALL_PATH = '*';
