import {
  DOCS_PATH,
  CLIENTS_PATH,
  EMPLOYEES_PATH,
  NOTIFICATIONS_PATH,
  SETTINGS_PATH,
  NOTIFY_PATH,
  UPLOAD_INFO_PATH,
  LICENSE_PATH,
  POLICY_PATH,
  AGREEMENT_PATH,
} from '../../utils/routes_consts';

export const menuItems = [
  { link: DOCS_PATH, name: 'Документы', roles: [] },
  { link: CLIENTS_PATH, name: 'Клиенты', roles: [] },
  { link: EMPLOYEES_PATH, name: 'Сотрудники', roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR'] },
  { link: NOTIFICATIONS_PATH, name: 'Уведомления и рассылки', roles: [] },
  { link: SETTINGS_PATH, name: '', roles: [] },
  { link: NOTIFY_PATH, name: '', roles: [] },
  { link: UPLOAD_INFO_PATH, name: '', roles: [] },
  { link: LICENSE_PATH, name: '', roles: [] },
  { link: POLICY_PATH, name: '', roles: [] },
  { link: AGREEMENT_PATH, name: '', roles: [] },
];
