import React from 'react';
import { SVGProps } from './types';

export const LoginIcon = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    {...props}
  >
    <path
      d="M26.2266 28.8984C24.2227 28.8984 22.5914 30.5367 22.5914 32.5547C22.5914 34.5727 24.2227 36.2109 26.2266 36.2109C28.2305 36.2109 29.8617 34.5727 29.8617 32.5547C29.8617 30.5367 28.2305 28.8984 26.2266 28.8984ZM65.25 11.25H6.75C5.50547 11.25 4.5 12.2555 4.5 13.5V58.5C4.5 59.7445 5.50547 60.75 6.75 60.75H65.25C66.4945 60.75 67.5 59.7445 67.5 58.5V13.5C67.5 12.2555 66.4945 11.25 65.25 11.25ZM42.75 29.5312C42.75 29.2219 42.8203 28.9688 42.9117 28.9688H51.5883C51.6797 28.9688 51.75 29.2219 51.75 29.5312V32.9062C51.75 33.2156 51.6797 33.4688 51.5883 33.4688H42.9117C42.8203 33.4688 42.75 33.2156 42.75 32.9062V29.5312ZM36.7031 47.3203H33.6164C33.3211 47.3203 33.082 47.0883 33.0609 46.793C32.7938 43.2422 29.8266 40.4297 26.2266 40.4297C22.6266 40.4297 19.6594 43.2422 19.3922 46.793C19.3711 47.0883 19.132 47.3203 18.8367 47.3203H15.75C15.6737 47.3204 15.5982 47.305 15.528 47.275C15.4579 47.245 15.3946 47.201 15.342 47.1457C15.2894 47.0905 15.2485 47.0251 15.222 46.9536C15.1954 46.8821 15.1837 46.8059 15.1875 46.7297C15.3844 42.982 17.4375 39.7195 20.4328 37.8633C19.1119 36.4113 18.382 34.5176 18.3867 32.5547C18.3867 28.2023 21.8953 24.6797 26.2195 24.6797C30.5437 24.6797 34.0523 28.2023 34.0523 32.5547C34.0523 34.6008 33.2789 36.457 32.0063 37.8633C35.0086 39.7266 37.0547 42.982 37.2516 46.7297C37.2797 47.0531 37.0266 47.3203 36.7031 47.3203ZM56.3133 43.5938H43.2492C42.975 43.5938 42.75 43.3406 42.75 43.0312V39.6562C42.75 39.3469 42.975 39.0938 43.2492 39.0938H56.3062C56.5805 39.0938 56.8055 39.3469 56.8055 39.6562V43.0312H56.8125C56.8125 43.3406 56.5875 43.5938 56.3133 43.5938Z"
      fill="#52C41A"
    />
  </svg>
);
